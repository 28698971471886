import { FiUsers } from 'react-icons/fi';

const Card = ({ keyName,value,icon }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center space-y-4 w-full max-w-sm mx-auto">
      <div className="bg-green-100 border-2 text-2xl text-green-600 border-green-500 rounded-full p-4 animate-pulse">
        {icon}
      </div>
      <div className="text-center">
        <h2 className="text-xl font-semibold text-gray-800">{keyName}</h2>
        <p className="text-2xl font-bold text-gray-900">{value}</p>
      </div>
    </div>
  );
};

export default Card;