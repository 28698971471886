// collectionCenterReducer.js
import * as actionTypes from   '../actions/collectionCenterAction';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const collectionCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COLLECTION_CENTERS_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_COLLECTION_CENTERS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case actionTypes.FETCH_COLLECTION_CENTERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // Similarly, handle add collection center actions
    default:
      return state;
  }
};

export default collectionCenterReducer;
