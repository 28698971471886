import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import httpWithoutCommon from '../services/httpWithoutCommon';

const PasswordResetForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if password and confirm password match
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await httpWithoutCommon.post(`/reset-password/${token}/`, { password });
      setMessage(response.data.detail);
      navigate('/login');
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded-lg max-w-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Create New Password</h2>
        {message && <p className="text-red-500 text-center py-2 bg-gray-200">{message}</p>}
        <form onSubmit={handleSubmit} className="space-y-10 mt-6">
          <input
            type="password"
            placeholder="Enter new password"
            value={password}
            className="w-full px-4 py-2 border rounded shadow-sm"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            className="w-full px-4 py-2 border rounded shadow-sm"
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetForm;
