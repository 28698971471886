import React from 'react';
import { AiFillNotification, AiOutlineLogout } from 'react-icons/ai';
import { FaHandPointLeft } from 'react-icons/fa';
import { FiBell, FiLogOut, FiMenu, FiX } from 'react-icons/fi';
import Logout from './Logout';

const Header = ({ isOpen, setIsOpen }) => {
  return (
    <div className="flex justify-end  items-center p-4 bg-blue-700 shadow-md">
      <div className="flex items-center space-x-4">
        <button className="focus:outline-none text-white">
          <FaHandPointLeft size={24} />
        </button>
        <button className="focus:outline-none text-white">
          <Logout />
        </button>
        <button 
    className={`lg:hidden focus:outline-none text-white bg-white border-3 border-white 
                w-8 h-8 flex items-center justify-center rounded-lg`} 
    onClick={() => setIsOpen(!isOpen)}
  >
    {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
  </button>
      </div>
    </div>
  );
};

export default Header;
