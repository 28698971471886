import { FETCH_REFERRED_BY_REQUEST, FETCH_REFERRED_BY_SUCCESS, FETCH_REFERRED_BY_FAILURE, ADD_REFERRED_BY_SUCCESS } from "../actions/doctorAction";


const initialState = {
    data: [],
    loading: false,
    error: null,
    success: null,
  };
  
const doctorReducer = (state = initialState, action) => {
    
    switch (action.type) {
      case FETCH_REFERRED_BY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_REFERRED_BY_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false,
          success: true,
          error: null,
        };
      case FETCH_REFERRED_BY_FAILURE:
        return {
          ...state,
          referredByOptions: [],
          loading: false,
          error: action.payload,
        };
        case 'ADD_REFERRED_BY_SUCCESS':
          return {
            ...state,
            loading: false,
            error: null,
            success:true
          };
        case 'ADD_REFERRED_BY_FAILURE':
          return {
            ...state,
            loading: false,
            error: action.payload
          };
      default:
        return state;
    }
  };
  
  export default doctorReducer;