import { all } from 'redux-saga/effects';
import { watchAddPatient, watchFetchNextPatient, watchFetchPatient, watchFetchPatientList, watchUpdatePatient, watchUploadPdf } from './patientSaga';
import { watchLoginUser } from './authSaga';
import { watchAddNormalValue, watchAddTestDatabase, watchFetchNextTestDatabase, watchFetchNormalValue, watchFetchTestDatabase } from './testDatabaseSaga';
import { watchAddDoctor, watchFetchDoctor, } from './doctorSaga';
import { watchFetchCollectionAgents } from './collectionAgentSaga';
import { watchFetchCollectionCenters } from './collectionCenterSaga';
import { watchAddTestValue, watchFetchTestValue } from './testReportSaga';


export default function* rootSaga() {
  yield all([
    watchLoginUser(),
    watchAddPatient(),
    watchUpdatePatient(),
    watchFetchTestDatabase(),
    watchAddNormalValue(),
    watchAddTestDatabase(),
    watchFetchNormalValue(),
    watchFetchDoctor(),
    watchAddDoctor(),
    watchFetchCollectionAgents(),
    watchFetchCollectionCenters(),
    watchFetchTestValue(),
    watchAddTestValue(),
    watchFetchPatient(),
    watchFetchPatientList(),
    watchUploadPdf(),
    watchFetchNextPatient(),
    watchFetchNextTestDatabase()
    // Other sagas...
  ]);
}