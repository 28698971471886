// src/store/actions/authActions.js
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const loginRequest = (credentials) => ({
  type: LOGIN_REQUEST,
  payload: credentials,
});

export const loginSuccess = (data) => {
    // Save the access token to local storage
    localStorage.setItem('accessToken', data.access);
    return {
      type: LOGIN_SUCCESS,
      payload: data,
    };
  };

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
