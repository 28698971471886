// actions/testDatabaseActions.js

// Action Types
export const FETCH_TEST_DATABASE_REQUEST = 'FETCH_TEST_DATABASE_REQUEST';
export const FETCH_TEST_DATABASE_SUCCESS = 'FETCH_TEST_DATABASE_SUCCESS';
export const FETCH_TEST_DATABASE_FAILURE = 'FETCH_TEST_DATABASE_FAILURE';

export const ADD_TEST_DATABASE_REQUEST = 'ADD_TEST_DATABASE_REQUEST';
export const ADD_TEST_DATABASE_SUCCESS = 'ADD_TEST_DATABASE_SUCCESS';
export const ADD_TEST_DATABASE_FAILURE = 'ADD_TEST_DATABASE_FAILURE';

export const ADD_NORMAL_VALUE_REQUEST = 'ADD_NORMAL_VALUE_REQUEST';
export const ADD_NORMAL_VALUE_SUCCESS = 'ADD_NORMAL_VALUE_SUCCESS';
export const ADD_NORMAL_VALUE_FAILURE = 'ADD_NORMAL_VALUE_FAILURE';
export const FETCH_NORMAL_VALUE_REQUEST = 'FETCH_NORMAL_VALUE_REQUEST';
export const FETCH_NORMAL_VALUE_SUCCESS = 'FETCH_NORMAL_VALUE_SUCCESS';
export const FETCH_NORMAL_VALUE_FAILURE = 'FETCH_NORMAL_VALUE_FAILURE';
export const FETCH_TEST_LIST_CASE_ID = 'FETCH_TEST_LIST_CASE_ID';
export const FETCH_TEST_LIST_CASE_ID_SUCCESS = 'FETCH_TEST_LIST_CASE_ID_SUCCESS';
export const ADD_TEST_RESULT = 'ADD_TEST_RESULT';
export const FETCH_NEXT_TEST_DATABASE_REQUEST = 'FETCH_NEXT_TEST_DATABASE_REQUEST';

// Action Creators
export const fetchTestDatabaseRequest = (searchTerm) => ({
  type: FETCH_TEST_DATABASE_REQUEST,
  payload:searchTerm
});
export const fetchNextTestDatabaseRequest = (nextPageUrl) => ({
  type: FETCH_NEXT_TEST_DATABASE_REQUEST,
  payload: nextPageUrl,
});

export const fetchTestDatabaseSuccess = (data) => ({
  type: FETCH_TEST_DATABASE_SUCCESS,
  payload: data,
});

export const fetchTestDatabaseFailure = (error) => ({
  type: FETCH_TEST_DATABASE_FAILURE,
  payload: error,
});

export const addTestDatabaseRequest = (testDatabaseData) => ({
  type: ADD_TEST_DATABASE_REQUEST,
  payload: testDatabaseData,
});

export const addTestDatabaseSuccess = (testDatabase) => ({
  type: ADD_TEST_DATABASE_SUCCESS,
  payload: testDatabase,
});

export const addTestDatabaseFailure = (error) => ({
  type: ADD_TEST_DATABASE_FAILURE,
  payload: error,
});


// Action Creators

export const fetchNormalValueRequest = (testid) => ({
  type: FETCH_NORMAL_VALUE_REQUEST,
  payload:testid
});

export const fetchNormalValueSuccess = (data) => ({
  type: FETCH_NORMAL_VALUE_SUCCESS,
  payload: data,
});

export const fetchNormalValueFailure = (error) => ({
  type: FETCH_NORMAL_VALUE_FAILURE,
  payload: error,
});
export const addNormalValueRequest = (testDatabaseId, normalValueData) => ({
    type: ADD_NORMAL_VALUE_REQUEST,
    payload: { testDatabaseId, normalValueData },
  });
  
  export const addNormalValueSuccess = (testDatabaseId, normalValue) => ({
    type: ADD_NORMAL_VALUE_SUCCESS,
    payload: { testDatabaseId, normalValue },
  });
  
  export const addNormalValueFailure = (error) => ({
    type: ADD_NORMAL_VALUE_FAILURE,
    payload: error,
  });