
import { FETCH_TEST_DATABASE_REQUEST,
    FETCH_TEST_DATABASE_SUCCESS,
    FETCH_TEST_DATABASE_FAILURE,
    ADD_TEST_DATABASE_REQUEST,
    ADD_TEST_DATABASE_SUCCESS,
    ADD_TEST_DATABASE_FAILURE,
    ADD_NORMAL_VALUE_REQUEST,
    ADD_NORMAL_VALUE_SUCCESS,
    ADD_NORMAL_VALUE_FAILURE,FETCH_NORMAL_VALUE_REQUEST,FETCH_NORMAL_VALUE_SUCCESS,FETCH_NORMAL_VALUE_FAILURE, 
    FETCH_NEXT_TEST_DATABASE_REQUEST} from '../actions/testDatabaseAction';


const initialState = {
  data: {
    results: [],
    next: null,
    previous: null,
    count: 0
},
  loading: false,
  error: null,
};

const testDatabaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEST_DATABASE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEXT_TEST_DATABASE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEST_DATABASE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          results: [ ...action.payload.results],
          next: action.payload.next,
        },
      };
    case FETCH_TEST_DATABASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_TEST_DATABASE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_TEST_DATABASE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      };
    case ADD_TEST_DATABASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_NORMAL_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NORMAL_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_NORMAL_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_NORMAL_VALUE_REQUEST:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case ADD_NORMAL_VALUE_SUCCESS:
        return {
            ...state,
            loading: false,
            // handle success if needed
        };
    case ADD_NORMAL_VALUE_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
        };
    default:
      return state;
  }
};

export default testDatabaseReducer;