import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const WhatsAppButton = () => {
  return (
    <FloatingWhatsApp
      phoneNumber="+919996222698"
      accountName="Lab2Easy"
      avatar='/logo.png'
      message="Hello! How can we help you?"
      allowClickAway
    />
  );
};

export default WhatsAppButton;