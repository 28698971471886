
import { FETCH_TEST_DATABASE_FAILURE } from '../actions/testDatabaseAction';
import { FETCH_TEST_VALUES_FAILURE,FETCH_TEST_VALUES_REQUEST,FETCH_TEST_VALUES_SUCCESS,ADD_TEST_VALUE_REQUEST,
    ADD_TEST_VALUE_SUCCESS,
    ADD_TEST_VALUE_FAILURE, } from '../actions/testReportAction';


const initialState = {
  data: [],
  loading: false,
  error: null,
};

const testReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEST_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TEST_VALUES_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_TEST_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_TEST_VALUE_REQUEST:
        return {
            ...state,
            loading: true,
            error: null,
        };
    case ADD_TEST_VALUE_SUCCESS:
        return {
            ...state,
            loading: false,
        };
    case ADD_TEST_VALUE_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            };
    default:
      return state;
  }
};

export default testReportReducer;