import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import Navbar from '../components/Navbar';
import EditPatientForm from '../components/EditPatientForm';
import { addPatientRequest, fetchPatientRequest, updatePatientRequest } from '../store/actions/patientActions'; // Import fetch and update actions
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditCaseForm from '../components/EditCaseForm';
import Header from '../components/Header';

const EditPatientPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  
  const { caseId } = useParams(); // Get patient case ID from URL params
  const [patientData, setPatientData] = useState({});
  const [caseData, setCaseData] = useState({});
  const [testData, setTestData] = useState({});
  const [paymentData, setPaymentData] = useState({});

  const patientState = useSelector((state) => state.patient);
  const { data: patientValue, loading: patientLoading, error: patientError, success: patientSuccess } = patientState;

  useEffect(() => {
    if (caseId) {
      dispatch(fetchPatientRequest(caseId)); // Fetch patient case data if ID is provided
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (patientValue && caseId) {
      setPatientData(patientValue.patient);
      setCaseData({
        referredBy: patientValue.referred_by,
        collectionCenter: patientValue.collection_center,
        agent: patientValue.collection_agent,
        collectionDate: patientValue.collection_date,
        reportingDate: patientValue.reporting_date,
        generateReportOnline: patientValue.generateReportOnline
      });
      
    
    }
  }, [patientValue, caseId]);
  

  const handleAddOrUpdatePatientAndCase = async () => {
    const currentDate = new Date();
    const dob = new Date(patientData.age_years, patientData.age_month - 1, patientData.age_day);
    const ageInYears = currentDate.getFullYear() - dob.getFullYear();

    if (ageInYears < 4) {
      toast.error('Age should be greater than 4 years.', {
        position: 'top-right',
        autoClose: 2000
      });
      return;
    }

    const requestData = {
      patient: patientData,
      referred_by: caseData.referredBy,
      collection_center: caseData.collectionCenter,
      collection_agent: caseData.agent,
      collection_date: caseData.collectionDate,
      reporting_date: caseData.reportingDate,
      generateReportOnline: caseData.generateReportOnline,
    };

    if (caseId) {
      dispatch(updatePatientRequest(caseId, requestData));
    } 
  };

  useEffect(() => {
   if (patientError) {
      toast.error(patientError, {
        position: 'top-right',
        autoClose: 2000
      });
    }
  }, [patientError, patientSuccess, navigate, patientValue]);

  return (
    <div className="flex h-screen">
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className="flex-1 lg:ml-64">
                <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <EditPatientForm setPatientData={setPatientData} patientData={patientData} />
        <EditCaseForm caseData={caseData} setCaseData={setCaseData} />
        <div className="text-right mt-4">
          <button
            className='bg-blue-500 px-4 py-2 rounded-md text-white text-lg font-semibold hover:bg-blue-800'
            onClick={handleAddOrUpdatePatientAndCase}
            disabled={patientLoading}
          >
            {patientLoading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EditPatientPage;
