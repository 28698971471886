import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTestDatabaseRequest,
  addTestDatabaseRequest,
  fetchTestDatabaseByPageRequest,
  fetchNextTestDatabaseRequest,
} from '../store/actions/testDatabaseAction';
import { useNavigate } from 'react-router-dom';

const TestDatabaseComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testDatabaseState = useSelector((state) => state.testDatabase);

  const { data: testDatabases, loading: testDatabasesLoading, error: testDatabasesError } = testDatabaseState;

  const [newTestDatabase, setNewTestDatabase] = useState({
    name: '',
    short_name: '',
    unit: '',
    rate: '',
    interpretation: '',
  });

  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [previousPageUrl, setPreviousPageUrl] = useState(null);

  useEffect(() => {
    dispatch(fetchTestDatabaseRequest()); // Fetch initial data
  }, [dispatch]);

  useEffect(() => {
    if (testDatabases) {
      setNextPageUrl(testDatabases.next);
      setPreviousPageUrl(testDatabases.previous);
    }
  }, [testDatabases]);

  const handleAddTestDatabase = () => {
    dispatch(addTestDatabaseRequest(newTestDatabase));
    setNewTestDatabase({ name: '', short_name: '', unit: '', rate: '', interpretation: '' });
  };

  const handleNormalValueAddButton = (selectedTestId) => {
    navigate(`/add-normal-value/${selectedTestId}`);
  };

  const handlePageChange = (url) => {
    if (url) {
      dispatch(fetchNextTestDatabaseRequest(url));
    }
  };

  if (testDatabasesLoading) return <div>Loading...</div>;
  if (testDatabasesError) return <div>Error: {testDatabasesError.message}</div>;

  return (
    <div className="w-full h-screen mx-auto bg-blue-100 shadow-md rounded px-8 pt-6 pb-8 my-4">
      <h1 className="text-3xl mb-4 text-blue-800">Test Database</h1>
      <div className="mb-4">
        <h2 className="text-xl mb-2 text-blue-800">Add New Test Database Entry</h2>
        <div className="mb-2 py-2 px-3 border-2 shadow-xl border-black ">
          <div>
            <input
              type="text"
              placeholder="Name"
              value={newTestDatabase.name}
              onChange={(e) => setNewTestDatabase({ ...newTestDatabase, name: e.target.value })}
              className="mr-2 p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              placeholder="Short Name"
              value={newTestDatabase.short_name}
              onChange={(e) => setNewTestDatabase({ ...newTestDatabase, short_name: e.target.value })}
              className="mr-2 p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              placeholder="Unit"
              value={newTestDatabase.unit}
              onChange={(e) => setNewTestDatabase({ ...newTestDatabase, unit: e.target.value })}
              className="mr-2 p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              placeholder="Rate"
              value={newTestDatabase.rate}
              onChange={(e) => setNewTestDatabase({ ...newTestDatabase, rate: e.target.value })}
              className="mr-2 p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              placeholder="Interpretation"
              value={newTestDatabase.interpretation}
              onChange={(e) => setNewTestDatabase({ ...newTestDatabase, interpretation: e.target.value })}
              className="mr-2 p-2 border border-gray-300 rounded"
            />
            <button
              onClick={handleAddTestDatabase}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded shadow"
            >
              Add Test Database
            </button>
          </div>
        </div>
      </div>

      <h2 className="text-xl mb-2 text-blue-800">Test Databases</h2>
      <table className="w-full border-collapse mb-4">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Short Name</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">Rate</th>
            <th className="border border-gray-300 px-4 py-2">Interpretation</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {testDatabases?.results?.map((testDatabase) => (
            testDatabase.is_group === false && (
              <tr key={testDatabase.id}>
                <td className="border border-gray-300 px-4 py-2">{testDatabase.name}</td>
                <td className="border border-gray-300 px-4 py-2">{testDatabase.short_name}</td>
                <td className="border border-gray-300 px-4 py-2">{testDatabase.unit}</td>
                <td className="border border-gray-300 px-4 py-2">{testDatabase.rate}</td>
                <td className="border border-gray-300 px-4 py-2">{testDatabase.interpretation}</td>
                <td className="border border-gray-300 px-4 py-2">
                  <button
                    onClick={() => handleNormalValueAddButton(testDatabase.id)}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded shadow"
                  >
                    Add Normal Value
                  </button>
                </td>
              </tr>
            )
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(previousPageUrl)}
          disabled={!previousPageUrl}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded shadow mr-2"
        >
          Prev
        </button>
        <button
          onClick={() => handlePageChange(nextPageUrl)}
          disabled={!nextPageUrl}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded shadow"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TestDatabaseComponent;
