import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReferredByRequest } from '../store/actions/doctorAction';

const AddReferredByModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const error = useSelector(state => state.doctor.error);
  const success = useSelector(state => state.doctor.success); // Assuming you have a success state in your Redux store

  const [newReferredBy, setNewReferredBy] = useState({
    first_name: '',
    last_name: '',
    department: '',
    email: '',
    mobile_number: '',
    address: '',
    commission: '',
    is_active: true // Set a default value for is_active
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReferredBy(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddReferredBy = () => {
    dispatch(addReferredByRequest(newReferredBy));
    if(success){
      onClose()
    }
  };


  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-xl p-8 rounded-md">
        <h2 className="text-xl font-bold mb-4 text-blue-500">Add Doctor</h2>
        {error && <p className="text-red-500 mb-4">{error.message}</p>} {/* Display error message */}
        <input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={newReferredBy.first_name}
          onChange={handleInputChange}
          className="border border-gray-300 rounded-md mb-4 w-full px-3 py-2"
        />
        <input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={newReferredBy.last_name}
          onChange={handleInputChange}
          className="border border-gray-300 rounded-md mb-4 w-full px-3 py-2"
        />
        <input
          type="text"
          name="department"
          placeholder="Department"
          value={newReferredBy.department}
          onChange={handleInputChange}
          className="border border-gray-300 rounded-md mb-4 w-full px-3 py-2"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={newReferredBy.email}
          onChange={handleInputChange}
          className="border border-gray-300 rounded-md mb-4 w-full px-3 py-2"
        />
        <input
          type="text"
          name="mobile_number"
          placeholder="Mobile Number"
          value={newReferredBy.mobile_number}
          onChange={handleInputChange}
          className="border border-gray-300 rounded-md mb-4 w-full px-3 py-2"
        />
        <input
          type="text"
          name="commission"
          placeholder="Commission in %"
          value={newReferredBy.commission}
          onChange={handleInputChange}
          className="border border-gray-300 rounded-md mb-4 w-full px-3 py-2"
        />
        <textarea
          name="address"
          placeholder="Address"
          value={newReferredBy.address}
          onChange={handleInputChange}
          className="border border-gray-300 rounded-md mb-4 w-full px-3 py-2"
        />
        <div className="flex justify-end">
          <button
            onClick={handleAddReferredBy}
            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md mr-2"
          >
            Add
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-md"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddReferredByModal;
