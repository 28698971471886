import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AddPatientPage from './pages/AddPatientPage';
import AddTestDatabase from './pages/AddTestDatabase';
import AddTestValuePage from './pages/AddTestValuePage';
import PatientDetail from './pages/PatientDetail';
import AddNormalValuePage from './pages/AddNormalValuePage';
import PDFReport from './pages/PDFReport';
import PatientEntryListPage from './pages/PatientEntryListPage';
import AddDoctor from './pages/AddDoctor';
import PrivateRoute from './components/PrivateRoute';
import PasswordResetRequestForm from './pages/PasswordResetRequestForm';
import PasswordResetForm from './pages/PasswordResetForm';
import ViewPDFReport from './pages/ViewPDFReport';
import ReportView from './pages/ReportView';
import EditPatientPage from './pages/EditPatientPage';
import EditPatientForm from './components/EditPatientForm';
import DownloadReportView from './pages/DownloadReportView';
import EditLabSection from './components/EditLabSection';
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />  
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/add-patient" element={<PrivateRoute><AddPatientPage /></PrivateRoute>} />
        <Route path="/patient-detail/:caseId" element={<PrivateRoute><PatientDetail /></PrivateRoute>} />
        <Route path="/edit-patient/:caseId" element={<PrivateRoute><EditPatientPage /></PrivateRoute>} />
        <Route path="/edit-test/:caseId" element={<PrivateRoute><EditLabSection /></PrivateRoute>} />
        
        <Route path="/add-normal-value/:testId" element={<PrivateRoute><AddNormalValuePage /></PrivateRoute>} />
        <Route path="/add-doctor" element={<PrivateRoute><AddDoctor /></PrivateRoute>} />
        <Route path="/add-test" element={<AddTestDatabase />} />
        <Route path="/password-reset" element={<PasswordResetRequestForm />} />
        <Route  path="/reset-password/:token" element={<PasswordResetForm  />} />
        <Route path="/view-pdf-report/:caseId" element={<ViewPDFReport />} />
        <Route path="/view-report/:caseId" element={<ReportView />} />
        <Route path="/add-test-value/:caseId" element={<AddTestValuePage />} />
        <Route path="/patient-list" element={<PatientEntryListPage />} />
        <Route path="/download-view-report/:caseId" element={<DownloadReportView />} />
        <Route path="/home" element={<Home />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
