export const ADD_TEST_VALUE = 'ADD_TEST_VALUE';
export const SAVE_TEST_VALUES = 'SAVE_TEST_VALUES';
export const FETCH_TEST_VALUES_REQUEST = 'FETCH_TEST_VALUES_REQUEST';
export const FETCH_TEST_VALUES_SUCCESS = 'FETCH_TEST_VALUES_SUCCESS';
export const FETCH_TEST_VALUES_FAILURE = 'FETCH_TEST_VALUES_FAILURE';
export const ADD_TEST_VALUE_REQUEST = 'ADD_TEST_VALUE_REQUEST';
export const ADD_TEST_VALUE_SUCCESS = 'ADD_TEST_VALUE_SUCCESS';
export const ADD_TEST_VALUE_FAILURE = 'ADD_TEST_VALUE_FAILURE';

export const fetchTestValueRequest = (caseid) => ({
    type: FETCH_TEST_VALUES_REQUEST,
    payload:caseid
  });
  
  export const fetchTestValueSuccess = (data) => ({
    type: FETCH_TEST_VALUES_SUCCESS,
    payload: data,
  });
  
  export const fetchTestValueFailure = (error) => ({
    type: FETCH_TEST_VALUES_FAILURE,
    payload: error,
  });

  
export const addTestValueRequest = (data) => ({
    type: ADD_TEST_VALUE_REQUEST,
    payload: data,
  });
  
  export const addTestValueSuccess = (data) => ({
    type: ADD_TEST_VALUE_SUCCESS,
    payload: data,
  });
  
  export const addTestValueFailure = (error) => ({
    type: ADD_TEST_VALUE_FAILURE,
    payload: error,
  });
  