import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi'; // Example: Hamburger menu icon
import { AiOutlineClose } from 'react-icons/ai'; // Example: Close icon
import { FiMenu } from 'react-icons/fi'; // Example: Menu icon
import { FaHome } from 'react-icons/fa'; // Example: Home icon
import { BsFillCameraFill } from 'react-icons/bs'; // Example: Camera icon
// Replace with your sample image file path

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
             
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <a href="#" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Home</a>
                  <a href="#" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Gallery</a>
                  <a href="#" className="text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Contact</a>
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">View notifications</span>
                  <BsFillCameraFill className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <AiOutlineClose className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <FiMenu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>

       
      </nav>

      {/* Sample content with padding */}
      <div className="p-10">
        <h1 className="text-2xl font-bold mb-4">Welcome to My Website!</h1>
        
        <p className="mt-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero eget magna tincidunt, vel laoreet odio fringilla.
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin consequat quis erat at aliquam.
          Duis sit amet ullamcorper risus, nec faucibus turpis. Aenean at nisl at orci accumsan viverra.
        </p>
      </div>
    </div>
  );
}

export default Home;
