import { combineReducers } from 'redux';
import authReducer from './authReducer';
import patientReducer from './patientReducer';
import testDatabaseReducer from './testDatabaseReducer';
import testNormalValueReducer from './testNormalValueReducer';
import doctorReducer from './doctorReducer';
import collectionAgentReducer from './collectionAgentReducer';
import collectionCenterReducer from './collectionCenterReducer';
import testReportReducer from './testReportReducer';
// Import other reducers as needed

const rootReducer = combineReducers({
  auth: authReducer,
  patient: patientReducer,
  testDatabase: testDatabaseReducer,
  testNormalValue:testNormalValueReducer,
  doctor:doctorReducer,
  center:collectionCenterReducer,
  agent:collectionAgentReducer,
  test:testReportReducer,
  // Add other reducers here
});

export default rootReducer;