import React from 'react';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const PatientCaseEntryDetail = ({ data }) => {
  const url = `https://lab2easy.com/download-view-report/${data?.case_id}`;
  const downloadUrl = `${url}?download=1`;

  const handleViewPDF = async () => {
    const input = document.getElementById('print-content');
    // Ensure the element has a white background
    input.style.backgroundColor = '#ffffff';
    
    // Generate canvas from the element
    const canvas = await html2canvas(input, { backgroundColor: '#ffffff' });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width);
    
    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    
    // Open PDF in a new tab
    const pdfBlobUrl = pdf.output('bloburl');
    window.open(pdfBlobUrl);
  };

  return (
    <>
      <div className="flex justify-start my-4 md:px-10 ">
        <a href={`/add-test-value/${data?.case_id}`} className="bg-blue-500 text-white font-semibold py-2 px-4 mx-5 rounded-md">Add Report Value</a>
        <a href={`/view-pdf-report/${data?.case_id}`} className="bg-purple-500 text-white font-semibold py-2 px-4 mx-5 rounded-md">View Report</a>
        <button onClick={handleViewPDF} className="bg-yellow-500 text-white font-semibold py-2 px-4 mx-5 rounded-md">Payment Preview</button>
        <a href={`/edit-patient/${data?.case_id}`} className="bg-black text-white font-semibold py-2 px-4 mx-5 rounded-md">Edit Patient</a>
        <a href={`/edit-test/${data?.case_id}`} className="bg-gray-600 text-white font-semibold py-2 px-4 mx-5 rounded-md">Edit Patient Test</a>
      </div>

      <div id="print-content" className="grid grid-cols-1 gap-4 px-10 bg-white">
        <div className="border m-4 rounded p-4">
          
        </div>
        <div className="border  rounded p-4">
          {/* Lab Details Card */}
          <div className="p-4 justify-center mx-auto">
            <p className="font-semibold text-xl text-center uppercase">{data?.lab_name}</p>
            <p className="font-semibold text-xl text-center">{data?.lab_address}</p>
            <p className="font-semibold text-xl text-center">{data?.phone} {data?.email}</p>
          </div>
          {/* Age Section Card */}
          <div className="border  rounded p-4">
            <hr className="my-4" />
            <div className="flex flex-col md:flex-row items-center md:justify-between">
              <div>
                <p><span className="font-semibold">Name:</span> {data?.patient?.first_name} {data?.patient?.last_name}</p>
                <p><span className="font-semibold">Address:</span> {data?.patient?.address}</p>
                <p><span className="font-semibold">Mobile:</span> {data?.patient?.mobile}</p>
                <p><span className="font-semibold">Email:</span> {data?.patient?.email}</p>
                <p><span className="font-semibold">Age:</span> {data?.age}</p>
                {/* Add more patient details here */}
              </div>
              <div>
                <p><span className="font-semibold">Reported Date:</span>{' '}
                  {data &&
                    new Date(data.reporting_date).toLocaleString('en-GB', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true, // Use 12-hour clock with AM/PM
                    })}
                </p>
                <p><span className="font-semibold">Collection Date:</span>{' '}
                  {data &&
                    new Date(data.collection_date).toLocaleString('en-GB', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true, // Use 12-hour clock with AM/PM
                    })}
                </p>
                <p><span className="font-semibold">Received By:</span> {data?.collected_by}</p>
              </div>
            </div>
          </div>
          {/* Test List Card */}
          <div className="border  rounded p-4">
            <h2 className="text-lg font-semibold mb-4">Test List</h2>
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">Test Name</th>
                    <th className="border px-4 py-2">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Map through test_panel and render nested tests */}
                  {data?.test_panel?.map((panel, index) => (
                    <React.Fragment key={index}>
                      {/* Render test panel if present */}
                      {panel?.test_panel_report && (
                        <tr>
                          <td className="border px-4 py-2 font-semibold">{panel.test_panel_report.name}</td>
                          <td className="border px-4 py-2 font-semibold">{panel.rate}</td>
                        </tr>
                      )}
                      {/* Map through test group if present */}
                      {panel?.test_group_report && (
                        <tr>
                          <td className="border px-4 py-2 font-semibold">{panel.test_group_report.name}</td>
                          <td className="border px-4 py-2 font-semibold">{panel.test_group_report.rate}</td>
                        </tr>
                      )}
                      {/* Render individual test reports if present */}
                      {panel?.test_report && (
                        <tr>
                          <td className="border px-4 py-2">{panel.test_report.name}</td>
                          <td className="border px-4 py-2">{panel.rate}</td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Payment History Card */}
          <div className="border rounded p-4 flex justify-between items-start">
      <div className=' '>
        <h2 className="text-lg font-semibold mb-4">Payment History</h2>
        <p><span className="font-semibold">Total Fee:</span> {data?.total_fee}</p>
        <p><span className="font-semibold">Discount:</span> {data?.discount ? `${data?.discount}` : 'No discount'}{data?.discount_type ? `${data?.discount_type}` : ''}</p>
        <p><span className="font-semibold">Remark:</span> {data?.discount_remark ? `${data?.discount_remark}` : ''}</p>
        
        <p><span className="font-semibold mt-4">Total Paid:</span> {data?.paid_amount}</p>
        <p><span className="font-semibold mt-4">Total Due:</span> {data?.due_amount}</p>
        <p><span className="font-semibold mt-4">Payment Type:</span> {data?.payment_type}</p>
        {/* Add more payment details here */}
      </div>
      <div className="ml-4 left-0">
      {data?.is_report_generated && (
         <QRCode value={`${data?.patient_report_pdf}?download=1`} />

      )}
        
       
      </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default PatientCaseEntryDetail;
