import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientListRequest, fetchNextPatientListRequest } from '../store/actions/patientActions';
import Navbar from '../components/Navbar';
import PatientEntryList from '../components/PatientEntryList';
import Header from '../components/Header';

const PatientEntryListPage = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const patientState = useSelector((state) => state.patient);
    const { data: patientValue, loading: patientLoading, nextPageUrl, error: patientError } = patientState;
    const [filters, setFilters] = useState({
        mobile: '',
        name: '',
        doctorBy: '',
        startDate: '',
        endDate: ''
    });
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        dispatch(fetchPatientListRequest());
    }, [dispatch]);
    const resetFilters = () => {
        setFilters({
            mobile: '',
            name: '',
            doctorBy: '',
            startDate: '',
            endDate: ''
        });
        dispatch(fetchPatientListRequest()); // Reload initial patient list
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const [day, month, year] = dateStr.split('-');
        return `${year}-${month}-${day}`;
    };

    const applyFilters = (patient) => {
        const { mobile, name, doctorBy, startDate, endDate } = filters;
    
        if (!mobile && !name && !doctorBy && !startDate && !endDate) {
            return true;
        }
    
        const mobileMatch = !mobile || (patient.patient.mobile && patient.patient.mobile.includes(mobile));
        const nameMatch = !name ||
            (patient.patient.first_name && patient.patient.first_name.toLowerCase().includes(name.toLowerCase())) ||
            (patient.patient.last_name && patient.patient.last_name.toLowerCase().includes(name.toLowerCase()));
        const doctorByMatch = !doctorBy ||
            (patient.doctor_by && patient.doctor_by.toLowerCase().includes(doctorBy.toLowerCase()));
        const startDateMatch = !startDate ||
            new Date(patient.created_at) >= new Date(formatDate(startDate));
        const endDateMatch = !endDate ||
            new Date(patient.created_at) <= new Date(formatDate(endDate));
    
        return mobileMatch && nameMatch && doctorByMatch && startDateMatch && endDateMatch;
    };

    const filteredPatients = Array.isArray(patientValue?.results) ? patientValue.results.filter(applyFilters) : [];
    const totalPages = Math.ceil(patientValue?.count / 15);

    const handleScroll = useCallback(() => {
        if (loadingMore || !nextPageUrl) return;

        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            setLoadingMore(true);
            dispatch(fetchNextPatientListRequest(nextPageUrl))
            setLoadingMore(false);
        }
    }, [dispatch, loadingMore, nextPageUrl]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div className="flex h-screen">
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className="flex-1 lg:ml-64">
                <Header isOpen={isOpen} setIsOpen={setIsOpen} />
                <div className="p-6 bg-gray-50 ">
                    <div className="bg-white shadow-md rounded-lg p-6 flex flex-col space-y-6">
                        <div className="flex flex-wrap justify-center w-full space-y-4 md:space-y-0 md:space-x-4">
                            <input
                                type="text"
                                name="mobile"
                                placeholder="Search by Mobile Number"
                                value={filters.mobile}
                                onChange={handleFilterChange}
                                className="border border-gray-300 rounded-md w-full md:w-auto px-3 py-2"
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Search by Name"
                                value={filters.name}
                                onChange={handleFilterChange}
                                className="border border-gray-300 rounded-md w-full md:w-auto px-3 py-2"
                            />
                            <input
                                type="text"
                                name="doctorBy"
                                placeholder="Search by Doctor"
                                value={filters.doctorBy}
                                onChange={handleFilterChange}
                                className="border border-gray-300 rounded-md w-full md:w-auto px-3 py-2"
                            />
                        </div>
                        <div className="flex flex-wrap justify-center w-full space-y-4 md:space-y-0 md:space-x-4">
                            <div className="flex items-center w-full md:w-auto">
                                <label className="text-sm font-medium text-gray-700 mb-1 md:mb-0 md:mr-2">Start Date:</label>
                                <input
                                    type="text"
                                    name="startDate"
                                    placeholder="dd-mm-yyyy"
                                    value={filters.startDate}
                                    onChange={handleFilterChange}
                                    className="border border-gray-300 rounded-md w-full md:w-auto px-3 py-2"
                                />
                            </div>
                            <div className="flex items-center w-full md:w-auto">
                                <label className="text-sm font-medium text-gray-700 mb-1 md:mb-0 md:mr-2">End Date:</label>
                                <input
                                    type="text"
                                    name="endDate"
                                    placeholder="dd-mm-yyyy"
                                    value={filters.endDate}
                                    onChange={handleFilterChange}
                                    className="border border-gray-300 rounded-md w-full md:w-auto px-3 py-2"
                                />
                            </div>
                        </div>
                        <button
                                onClick={resetFilters}
                                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md shadow"
                            >
                                Reset
                            </button>
                    </div>

                    <PatientEntryList entries={filteredPatients} />
                    {loadingMore && (
                        <div className="flex justify-center mt-4">
                            <svg className="animate-spin h-5 w-5 mr-3 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM20 12c0-3.042-1.135-5.824-3-7.938l-3 2.647A7.962 7.962 0 0120 12h4z"></path>
                            </svg>
                            <span>Loading more...</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PatientEntryListPage;
