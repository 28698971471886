
// collectionAgentActionTypes.js
export const FETCH_COLLECTION_AGENTS_REQUEST = 'FETCH_COLLECTION_AGENTS_REQUEST';
export const FETCH_COLLECTION_AGENTS_SUCCESS = 'FETCH_COLLECTION_AGENTS_SUCCESS';
export const FETCH_COLLECTION_AGENTS_FAILURE = 'FETCH_COLLECTION_AGENTS_FAILURE';

export const ADD_COLLECTION_AGENT_REQUEST = 'ADD_COLLECTION_AGENT_REQUEST';
export const ADD_COLLECTION_AGENT_SUCCESS = 'ADD_COLLECTION_AGENT_SUCCESS';
export const ADD_COLLECTION_AGENT_FAILURE = 'ADD_COLLECTION_AGENT_FAILURE';
// collectionAgentActions.js

  
  export const fetchCollectionAgentsRequest = () => ({
    type: FETCH_COLLECTION_AGENTS_REQUEST,
  });
  
  export const fetchCollectionAgentsSuccess = (collectionAgents) => ({
    type: FETCH_COLLECTION_AGENTS_SUCCESS,
    payload: collectionAgents,
  });
  
  export const fetchCollectionAgentsFailure = (error) => ({
    type: FETCH_COLLECTION_AGENTS_FAILURE,
    payload: error,
  });
  
  export const addCollectionAgentRequest = (collectionAgentData) => ({
    type: ADD_COLLECTION_AGENT_REQUEST,
    payload: collectionAgentData,
  });
  
  export const addCollectionAgentSuccess = (collectionAgent) => ({
    type: ADD_COLLECTION_AGENT_SUCCESS,
    payload: collectionAgent,
  });
  
  export const addCollectionAgentFailure = (error) => ({
    type: ADD_COLLECTION_AGENT_FAILURE,
    payload: error,
  });
  
