
import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchTestValueFailure,fetchTestValueRequest,fetchTestValueSuccess,FETCH_TEST_VALUES_FAILURE,
  FETCH_TEST_VALUES_REQUEST,FETCH_TEST_VALUES_SUCCESS,ADD_TEST_VALUE_REQUEST, addTestValueSuccess
} from  '../actions/testReportAction';
import httpCommon from '../../services/httpCommon';


const fetchTestValueAPI = (caseId) => {
    return httpCommon.get(`/patient-test-report/${caseId}/`);
  };
const addTestValueAPI = (testDatabaseData) => {
    return httpCommon.post('/patient-test-reports-update/', testDatabaseData);
};

  function* fetchTestValueSaga(action) {
    try {
        const caseId  = action.payload;
      const response = yield call(fetchTestValueAPI,caseId);
      yield put(fetchTestValueSuccess(response.data));
    } catch (error) {
      yield put(fetchTestValueFailure(error));
    }
  }
function* addTestValueSaga(action) {
    
    try {
        const { testValueData } = action.payload;
        const response = yield call(addTestValueAPI, action.payload);
        yield put(addTestValueSuccess(response.data));
    } catch (error) {
        yield put(addTestValueSuccess(error.message));
    }
}

export function* watchAddTestValue() {
    yield takeLatest(ADD_TEST_VALUE_REQUEST, addTestValueSaga);
}

export function* watchFetchTestValue() {
    yield takeLatest(FETCH_TEST_VALUES_REQUEST, fetchTestValueSaga);
}