// sagas/testDatabaseSaga.js

import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  FETCH_TEST_DATABASE_REQUEST,
  fetchTestDatabaseSuccess,
  fetchTestDatabaseFailure,
  ADD_TEST_DATABASE_REQUEST,
  addTestDatabaseSuccess,
  addTestDatabaseFailure,ADD_NORMAL_VALUE_REQUEST,
  addNormalValueSuccess,
  addNormalValueFailure,fetchNormalValueRequest,fetchNormalValueSuccess,fetchNormalValueFailure, FETCH_NORMAL_VALUE_REQUEST,
  FETCH_NEXT_TEST_DATABASE_REQUEST
} from  '../actions/testDatabaseAction';
import httpCommon from '../../services/httpCommon';

// API Calls
export const fetchTestDatabaseAPI = (searchKey) => {
  return httpCommon.get('/test-database/', {
    params: {
      search_key: searchKey,
    },
  });
};
const fetchTestDatabaseNextAPI = (url) => {

  console.log("dsflakldskfldsklfksdl---->>>>>>",url)
  return httpCommon.get(url);
};
const fetchNormalValueAPI = (testDatabaseId) => {
  console.log("api--call")
  return httpCommon.get(`/test/${testDatabaseId}/add-normal-value/`);
};

const addTestDatabaseAPI = (testDatabaseData) => {
  return httpCommon.post('/test-database/', testDatabaseData);
};
const addNormalValueAPI = (testDatabaseId, normalValueData) => {
    return httpCommon.post(`/test/${testDatabaseId}/add-normal-value/`, normalValueData);
  };
  
  // Saga
  function* fetchNormalValueSaga(action) {
    try {
      const testDatabaseId  = action.payload;
      console.log("saga",testDatabaseId)
      const response = yield call(fetchNormalValueAPI,testDatabaseId);
      yield put(fetchNormalValueSuccess(response.data));
    } catch (error) {
      yield put(fetchNormalValueFailure(error));
    }
  }
function* addNormalValueSaga(action) {
    try {
        const { testDatabaseId, normalValueData } = action.payload;
        console.log("respone---data",testDatabaseId)
        const response = yield call(addNormalValueAPI, testDatabaseId, normalValueData);
        yield put(addNormalValueSuccess(testDatabaseId, response.data));
    } catch (error) {
        yield put(addNormalValueFailure(error));
    }
}

// Sagas
function* fetchTestDatabaseSaga(action) {
  try {
    const response = yield call(fetchTestDatabaseAPI, action.payload);
    yield put(fetchTestDatabaseSuccess(response.data));
  } catch (error) {
    yield put(fetchTestDatabaseFailure(error));
  }
}
function* fetchNextTestDatabaseSaga(action) {
  try {
    const response = yield call(fetchTestDatabaseNextAPI, action.payload);
    yield put(fetchTestDatabaseSuccess(response.data));
  } catch (error) {
    yield put(fetchTestDatabaseFailure(error));
  }
}


function* addTestDatabaseSaga(action) {
  try {
    const response = yield call(addTestDatabaseAPI, action.payload);
    yield put(addTestDatabaseSuccess(response.data));
  } catch (error) {
    yield put(addTestDatabaseFailure(error));
  }
}

// Watchers
export function* watchFetchTestDatabase() {
  yield takeLatest(FETCH_TEST_DATABASE_REQUEST, fetchTestDatabaseSaga);
}
export function* watchFetchNextTestDatabase() {
  yield takeLatest(FETCH_NEXT_TEST_DATABASE_REQUEST, fetchNextTestDatabaseSaga);
}

export function* watchAddTestDatabase() {
  yield takeLatest(ADD_TEST_DATABASE_REQUEST, addTestDatabaseSaga);
}

  
  // Watcher
export function* watchFetchNormalValue() {
  yield takeLatest(FETCH_NORMAL_VALUE_REQUEST, fetchNormalValueSaga);
}
export function* watchAddNormalValue() {
    yield takeLatest(ADD_NORMAL_VALUE_REQUEST, addNormalValueSaga);
}
