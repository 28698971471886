// src/store/actions/patientActions.js
export const ADD_PATIENT_REQUEST = 'ADD_PATIENT_REQUEST';
export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS';
export const ADD_PATIENT_FAILURE = 'ADD_PATIENT_FAILURE';
export const FETCH_PATIENT_REQUEST = 'FETCH_PATIENT_REQUEST';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILURE = 'FETCH_PATIENT_FAILURE';
export const FETCH_PATIENT_LIST_REQUEST = 'FETCH_PATIENT_LIST_REQUEST';
export const FETCH_PATIENT_LIST_SUCCESS = 'FETCH_PATIENT_LIST_SUCCESS';
export const FETCH_PATIENT_LIST_FAILURE = 'FETCH_PATIENT_LIST_FAILURE';
export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE';
export const UPLOAD_PDF_REQUEST = 'UPLOAD_PDF_REQUEST'
export const UPLOAD_PDF_SUCCESS = 'UPLOAD_PDF_SUCCESS'
export const UPLOAD_PDF_FAILURE = 'UPLOAD_PDF_FAILURE'
export const FETCH_NEXT_PATIENT_LIST_REQUEST = 'FETCH_NEXT_PATIENT_LIST_REQUEST';

export const addPatientRequest = (data) => ({
    type: 'ADD_PATIENT_REQUEST',
    payload: data,
  });
  
  export const addPatientSuccess = (patient) => ({
    type: 'ADD_PATIENT_SUCCESS',
    payload: patient,
  });
  
  export const addPatientFailure = (error) => { // Log the error message
    return {
      type: 'ADD_PATIENT_FAILURE',
      error: error,
    };
  };
  export const fetchPatientRequest = (data) => { // Log the error message
    return {
      type: 'FETCH_PATIENT_REQUEST',
      payload: data,
    };
  };
  export const fetchPatientFailure = (error) => { // Log the error message
    return {
      type: 'FETCH_PATIENT_FAILURE',
      error: error,
    };
  };
  export const fetchPatientSuccess = (data) => ({
    type: 'FETCH_PATIENT_SUCCESS',
    payload: data,
  });


  export const fetchPatientListRequest = () => { // Log the error message
    return {
      type: 'FETCH_PATIENT_LIST_REQUEST',
   
    };
  };
  export const fetchNextPatientListRequest = (nextPageUrl) => ({
    type: FETCH_NEXT_PATIENT_LIST_REQUEST,
    payload: nextPageUrl,
});
  
  export const fetchPatientListFailure = (error) => { // Log the error message
    return {
      type: 'FETCH_PATIENT_LIST_FAILURE',
      error: error,
    };
  };
  export const fetchPatientListSuccess = (data) => ({
    type: 'FETCH_PATIENT_LIST_SUCCESS',
    payload: data,
  });
  export const updatePatientRequest = (caseId, patientData) => ({
    type: UPDATE_PATIENT_REQUEST,
    payload: { caseId, patientData },
  });
  
  export const updatePatientSuccess = () => ({
    type: UPDATE_PATIENT_SUCCESS,
  });
  
  export const updatePatientFailure = (error) => ({
    type: UPDATE_PATIENT_FAILURE,
    payload: error,
  });
  export const uploadPdfRequest = (caseId, pdfBlob) => ({
    type: UPLOAD_PDF_REQUEST,
    payload: { caseId, pdfBlob }
  });
  
  export const uploadPdfSuccess = () => ({
    type: UPLOAD_PDF_SUCCESS
  });
  
  export const uploadPdfFailure = (error) => ({
    type: UPLOAD_PDF_FAILURE,
    payload: { error }
  });
  