export const errorMessage = (errorObject) => {
    for (const key in errorObject) {
      if (Array.isArray(errorObject[key])) {
        // If it's an array, return the first item
        return errorObject[key][0];
      } else if (typeof errorObject[key] === 'object' && errorObject[key] !== null) {
        // If it's an object, recursively call errorMessage
        return errorMessage(errorObject[key]);
      }
    }
    // If no suitable error message found, return a default message
    return 'An unknown error occurred.';
  };