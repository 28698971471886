import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReferredByRequest, fetchReferredByRequest } from '../store/actions/doctorAction';
import Navbar from '../components/Navbar';
import AddReferredByModal from '../components/AddReferredByModal';
import Header from '../components/Header';

const DoctorPage = () => {
  const dispatch = useDispatch();
  const doctorState = useSelector((state) => state.doctor);

  const { data: doctors } = doctorState;
  const [isOpen, setIsOpen] = useState(false);

  

  // State for new doctor details
  const [newDoctor, setNewDoctor] = useState({
    first_name: '',
    last_name: '',
    department: '',
    email: '',
    mobile_number: '',
    address: '',
    commission:'',
    is_active: true
  });

  // State for toggling between add and list views
  const [showAddForm, setShowAddForm] = useState(false);

  // State for controlling the modal
  const [showModal, setShowModal] = useState(false);

  // Function to handle input changes in the add form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDoctor(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Function to handle adding a new doctor
  const handleAddDoctor = () => {
    dispatch(addReferredByRequest(newDoctor));
    // Clear form fields after adding
    setNewDoctor({
      first_name: '',
      last_name: '',
      department: '',
      email: '',
      mobile_number: '',
      address: '',
      commission:'',
      is_active: true
    });
    setShowModal(false);
  };

  // Function to handle opening the modal
  const handleDoctorModalOpen = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(fetchReferredByRequest());
  }, [showModal]);

  return (
    <>
      <div className="flex h-screen">
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex-1 lg:ml-64">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="p-6 bg-gray-50">
        <h2 className="text-xl font-bold mb-4">Doctor Page</h2>

        {/* Toggle between add form and doctor list */}
        <div>
          <button onClick={() => handleDoctorModalOpen()} className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md">
          Add New Doctor
          </button>
          {showModal && <AddReferredByModal onClose={handleModalClose} />}
        </div>

        {/* Conditional rendering based on showAddForm state */}
      
          <div>
            <h3>Doctor List</h3>
            <table className="w-full border-collapse border border-blue-500">
              <thead>
                <tr className="bg-blue-500 text-white">
                  <th className="px-4 py-2">First Name</th>
                  <th className="px-4 py-2">Last Name</th>
                  <th className="px-4 py-2">Department</th>
                  <th className="px-4 py-2">Email</th>
                  <th className="px-4 py-2">Mobile Number</th>
                  <th className="px-4 py-2">Commission in %</th>
                  <th className="px-4 py-2">Address</th>
                </tr>
              </thead>
              <tbody>
                {doctors.map((doctor) => (
                  <tr key={doctor.id} className="bg-white">
                    <td className="border px-4 py-2">{doctor.first_name}</td>
                    <td className="border px-4 py-2">{doctor.last_name}</td>
                    <td className="border px-4 py-2">{doctor.department}</td>
                    <td className="border px-4 py-2">{doctor.email}</td>
                    <td className="border px-4 py-2">{doctor.mobile_number}</td>
                    <td className="border px-4 py-2">{doctor.commission}</td>
                    <td className="border px-4 py-2">{doctor.address}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        
      </div>
      </div>
      </div>

    </>

  );
};

export default DoctorPage;
