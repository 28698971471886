import React, { useState, useEffect } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestDatabaseRequest } from '../store/actions/testDatabaseAction';

const LabSection = ({ setTestData, setPaymentData }) => {
  const dispatch = useDispatch();
  const testDatabaseState = useSelector((state) => state.testDatabase);
  const { data: tests = {}, loading, error } = testDatabaseState;

  const [searchTerm, setSearchTerm] = useState('');
  const [remark, setRemark] = useState('');
  const [filteredTests, setFilteredTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [discount, setDiscount] = useState({ amount: '', type: '₹' });
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [paymentType, setPaymentType] = useState('Cash');
  const [showDropdown, setShowDropdown] = useState(false);

  // Fetch data when searchTerm changes
  useEffect(() => {
    if (searchTerm.trim() !== '') {
      // Clear previous results and selections
      setFilteredTests([]);
      dispatch(fetchTestDatabaseRequest(searchTerm));
      
    } else {
      setShowDropdown(false);
      setFilteredTests([]);
    }
  }, [dispatch, searchTerm]);

  // Update filteredTests and show dropdown based on test data
  useEffect(() => {
    if (!loading && searchTerm.trim() !== '') {
      console.log(tests)
      if (tests.results && tests.results.length > 0) {
        setFilteredTests(tests.results);
        setShowDropdown(true);
      } else {
        setFilteredTests([]);
        setShowDropdown(false);
      }
    }
  }, [tests, searchTerm, loading]);

  // Update parent component data
  useEffect(() => {
    const totalFee = selectedTests.reduce((total, test) => total + test.rate, 0);
    const totalAmount = totalFee - (discount.type === '%' ? (totalFee * discount.amount) / 100 : discount.amount);
    const totalDue = totalAmount - totalPaidAmount;

    setTestData(selectedTests);
    setPaymentData({ totalFee, discount, totalPaidAmount, totalDue, paymentType, remark });
  }, [selectedTests, discount, totalPaidAmount, paymentType, remark, setTestData, setPaymentData]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRemarkInputChange = (e) => {
    setRemark(e.target.value);
  };

  const handleTestSelect = (test) => {
    if (!selectedTests.some((selectedTest) => selectedTest.id === test.id)) {
      setSelectedTests([...selectedTests, test]);
    }
    setSearchTerm('');
    setShowDropdown(false);
  };

  const handleTestRemove = (test) => {
    setSelectedTests(selectedTests.filter((t) => t.id !== test.id));
  };

  const handleDiscountChange = (e) => {
    setDiscount({ ...discount, amount: e.target.value });
  };

  const handleDiscountTypeChange = (type) => {
    setDiscount({ ...discount, type });
  };

  const calculateTotalFee = () => {
    return selectedTests.reduce((total, test) => total + test.rate, 0);
  };

  const totalFee = calculateTotalFee();
  const totalAmount = totalFee - (discount.type === '%' ? (totalFee * discount.amount) / 100 : discount.amount);
  const totalDue = totalAmount - totalPaidAmount;

  return (
    <section className="p-4">
      <div className="flex flex-col md:flex-row bg-blue-100 shadow-md rounded px-4 py-6 my-4 items-center justify-between relative">
        {/* Close button */}
        <AiFillCloseCircle
          className="text-red-500 absolute top-0 right-0 cursor-pointer"
          onClick={() => console.log('Close button clicked')}
        />
        {/* Search Test */}
        <input
          type="text"
          placeholder="Search Test"
          value={searchTerm}
          onChange={handleSearchInputChange}
          className="shadow appearance-none group border rounded py-2 px-3 mx-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 md:mb-0 w-full md:w-1/3"
        />
        {/* Display selected tests */}
        <div className="flex w-full md:w-1/2 mb-2 border border-blue-500 p-3 bg-blue-400 md:mb-0 md:mr-2 flex-wrap">
          {selectedTests.map((test) => (
            <div key={test.id} className="flex items-center bg-gray-200 rounded-md shadow-xl px-3 py-2 mb-2 mr-2">
              <span>{test.name}</span>
              <AiFillCloseCircle className="text-red-500 cursor-pointer ml-2" onClick={() => handleTestRemove(test)} />
            </div>
          ))}
        </div>
        {/* Total Test Fee */}
        <div className="w-full md:w-1/2 mb-2 md:mb-0 p-2 flex justify-between items-center">
          <span>Total Fee:</span>
          <input
            type="number"
            placeholder="Fee"
            value={calculateTotalFee()}
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-auto"
            readOnly
          />
        </div>
        {/* Discount */}
        <div className="w-full flex flex-col md:flex-row items-center mb-2 md:mb-0 md:w-1/2">
          <input
            type="text"
            placeholder="Discount"
            value={discount.amount}
            onChange={handleDiscountChange}
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2 w-full md:w-1/2 mb-2 md:mb-0"
          />
          <select
            value={discount.type}
            onChange={(e) => handleDiscountTypeChange(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto p-2"
          >
            <option value="₹">₹</option>
            <option value="%">%</option>
          </select>
          <input
            type="text"
            placeholder="Remarks"
            value={remark}
            onChange={handleRemarkInputChange}
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-1/2 ml-0 md:ml-4"
          />
        </div>
        
        {/* Test Selection Dropdown */}
        {showDropdown && (
          <div className="absolute top-full left-0 w-full md:w-1/4 bg-white shadow-md rounded border border-gray-300 mt-2 z-10">
            <ul className="p-2">
              {loading ? (
                <li className="py-1 px-2 text-gray-500">Loading...</li>
              ) : filteredTests.length > 0 ? (
                filteredTests.map((test) => (
                  <li
                    key={test.id}
                    className="cursor-pointer hover:bg-gray-100 py-1 px-2"
                    onClick={() => handleTestSelect(test)}
                  >
                    {test.name} - ({test.short_name}) - (₹ {test.rate})
                  </li>
                ))
              ) : (
                <li className="py-1 px-2 text-gray-500">No results found</li>
              )}
            </ul>
          </div>
        )}
      </div>
      <div className="flex justify-center my-4">
        <div className="w-full max-w-2xl">
          <div className="grid grid-cols-1 gap-4 bg-white shadow-md rounded-lg px-6 py-4">
            {/* Total Fee Card */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Total Fee</h2>
              <div className="flex justify-between mb-2">
                <span>Total Fee:</span>
                <span>₹{totalFee.toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Discount:</span>
                <span>{discount.type === '%' ? `${discount.amount}%` : `₹${discount.amount}`}</span>
              </div>
            </div>
            {/* Payment Details Card */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Payment Details</h2>
              <div className="flex justify-between mb-2">
                <span>Total Amount:</span>
                <span>₹{totalAmount.toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Total Paid Amount:</span>
                <input
                  type="text"
                  value={totalPaidAmount}
                  onChange={(e) => setTotalPaidAmount(Number(e.target.value))}
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex justify-between mb-2">
                <span>Total Due:</span>
                <span>₹{totalDue.toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Payment Type:</span>
                <select
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                  <option value="Online">Online</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LabSection;
