import { Tooltip } from '@react-pdf-viewer/core';
import React from 'react';
import { BsCheckAll, BsClock, BsFileMedicalFill, BsPencil } from 'react-icons/bs';
import { FaBusinessTime, FaCircle, FaTimesCircle, } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PatientEntryList = ({ entries }) => {
    const navigate = useNavigate();

    const formatDate = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const formattedDate = dateTime.toLocaleDateString(); // Format: MM/DD/YYYY
        const formattedTime = dateTime.toLocaleTimeString(); // Format: HH:MM:SS AM/PM
        return `${formattedDate} ${formattedTime}`;
    };

    const handleRowClick = (entry) => {
        navigate(`/patient-detail/${entry.case_id}`);
    };

    const handleEdit = (entry) => {
        // Logic for editing the entry
    };

    const handleGenerateReport = (entry) => {
        // Logic for generating report
    };

    return (
        <div className="patient-entry-list">
        <h2 className="text-2xl font-bold mb-6 text-blue-800">Patient Entry List</h2>
        <div className="overflow-x-auto">
            <table className="table-auto w-full bg-white rounded-lg shadow">
                <thead>
                    <tr className="bg-blue-200 text-sm font-semibold text-nowrap text-blue-900">
                        <th className="px-4 py-2">Patient ID</th>
                        <th className="px-4 py-2">Mobile</th>
                        <th className="px-4 py-2">First Name</th>
                        <th className="px-4 py-2">Last Name</th>
                        <th className="px-4 py-2">Gender</th>
                        <th className="px-4 py-2">Doctor By</th>
                        <th className="px-4 py-2">Date of Entry</th>
                        <th className="px-4 py-2">Reported Date</th>
                        <th className="px-4 py-2">Collection Date</th>
                        <th className="px-4 py-2">Report Status</th>
                        <th className="px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {entries.map((entry, index) => (
                        <tr key={index} className="hover:bg-blue-100 text-sm   cursor-pointer" onClick={() => handleRowClick(entry)}>
                            <td className="border px-4 py-2 text-center underline text-blue-600">{entry.case_id}</td>
                            <td className="border px-4 py-2 text-center">{entry.patient.mobile}</td>
                            <td className="border px-4 py-2 text-center">{entry.patient.first_name}</td>
                            <td className="border px-4 py-2 text-center">{entry.patient.last_name}</td>
                            <td className="border px-4 py-2 text-center">{entry.patient.gender}</td>
                            <td className="border px-4 py-2 text-center">{entry.doctor_by}</td>
                            <td className="border px-4 py-2 text-center">{formatDate(entry.created_at)}</td>
                            <td className="border px-4 py-2 text-center">{formatDate(entry.reporting_date)}</td>
                            <td className="border px-4 py-2 text-center">{formatDate(entry.collection_date)}</td>
                            <td className="border px-4 py-2 text-center">
                                {entry.is_report_generated ? (
                                    <BsCheckAll style={{ color: 'green', fontSize: '1em' }} />
                                ) : (
                                    <BsClock style={{ color: 'red', fontSize: '1em' }} />
                                )}
                            </td>
                            <td className="border px-4 py-2 text-center flex justify-around">
                                <BsPencil 
                                    style={{ color: 'blue', fontSize: '1em' }} 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(entry);
                                    }} 
                                    data-tip="Edit Entry"
                                />
                                <BsFileMedicalFill 
                                    style={{ color: 'green', fontSize: '1em' }} 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleGenerateReport(entry);
                                    }} 
                                    data-tip="Generate Report"
                                />
                                <FaCircle 
                                    style={{ color: 'green', fontSize: '1em' }} 
                                    data-tip="Normal Value"
                                />
                                <Tooltip place="top" type="dark" effect="solid" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
    );
};

export default PatientEntryList;
