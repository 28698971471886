// patientReducer.js

import { FETCH_PATIENT_FAILURE, FETCH_PATIENT_LIST_FAILURE, FETCH_PATIENT_LIST_REQUEST, FETCH_PATIENT_LIST_SUCCESS, 
  FETCH_PATIENT_REQUEST, 
  FETCH_PATIENT_SUCCESS,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE, 
  UPLOAD_PDF_FAILURE,
  UPLOAD_PDF_SUCCESS,
  UPLOAD_PDF_REQUEST,
  FETCH_NEXT_PATIENT_LIST_REQUEST} from "../actions/patientActions";

const initialState = {
  patients: [],
  data: {
    results: [],
    next: null,
    previous: null,
    count: 0
},
  success: false,
  loading: false,
  error: null
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_PATIENT_REQUEST':
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };
    case 'ADD_PATIENT_SUCCESS':
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        data: action.payload
      };
    case 'ADD_PATIENT_FAILURE': // Log the action payload
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error
      };
      case FETCH_PATIENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
          data: action.payload,
        };
    case FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_PATIENT_LIST_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
    case FETCH_NEXT_PATIENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
          results: [...state.data.results, ...action.payload.results]
      },
        nextPageUrl: action.payload.next,
      };
    case FETCH_PATIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case UPDATE_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
      case UPLOAD_PDF_REQUEST:
        return {
          ...state,
          uploading: true,
          error: null
        };
      case UPLOAD_PDF_SUCCESS:
        return {
          ...state,
          uploading: false,
          error: null
        };
      case UPLOAD_PDF_FAILURE:
        return {
          ...state,
          uploading: false,
          error: action.payload.error
        };
    default:
      return state;
  }
};

export default patientReducer;
