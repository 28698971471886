// src/store/sagas/authSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { LOGIN_REQUEST, loginSuccess, loginFailure } from '../actions/authActions';

import { errorMessage } from '../../services/utils';
import httpWithoutCommon from '../../services/httpWithoutCommon';

export const loginApi = async (credentials) => {
  try {
    const response = await httpWithoutCommon.post('/login/', credentials); // Use httpCommon.post
    return response.data;
  } catch (error) {
    console.log("api", error)
    throw new Error(error.message);
  }
};

function* loginUser(action) {
  try {
    const response = yield call(loginApi, action.payload);
    

    if (response.success){
      yield put(loginSuccess(response.data));

    }else{
      const err = errorMessage(response.error)
      yield put(loginFailure(err));

    }
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

export function* watchLoginUser() {
  yield takeLatest(LOGIN_REQUEST, loginUser);
}
