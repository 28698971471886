import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeImage = ({ value, size }) => (
  <QRCode
    value={value}  // Value to encode in QR code
    size={size}    // Size of the QR code image
    renderAs={'svg'} // Render as SVG or 'canvas' for PNG
    // Other props for customization: fgColor, bgColor, etc.
  />
);

export default QRCodeImage;
