import React, { useEffect, useState } from 'react';

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import TestDatabaseComponent from '../components/TestDatabaseComponent';
import AddNormalValueModal from '../components/AddNormalValueModal';
import CasePaymentDetailSection from './PatientCaseEntryDetail';
import PatientCaseEntryDetail from './PatientCaseEntryDetail';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientRequest } from '../store/actions/patientActions';
import Header from '../components/Header';



const PatientDetail = () => {
    const { caseId } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const patientState = useSelector((state) => state.patient);
    const { data: patientValue, loading: patientLoading, error: patientError } = patientState;

    
 
    useEffect(() => {
        dispatch(fetchPatientRequest(caseId));
    }, [dispatch]);
  
  return (
    <div className="flex h-screen">
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className="flex-1 lg:ml-64">
                <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="md:flex-col ">
      <PatientCaseEntryDetail data={patientValue} />
      </div>
    </div>
    </div>
  );
};

export default PatientDetail;