import React, { useState } from 'react';

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import TestDatabaseComponent from '../components/TestDatabaseComponent';
import AddNormalValueModal from '../components/AddNormalValueModal';
import CasePaymentDetailSection from './PatientCaseEntryDetail';
import PatientCaseEntryDetail from './PatientCaseEntryDetail';
import Header from '../components/Header';



const AddTestDatabase = () => {
  const [isOpen, setIsOpen] = useState(false);
  const data = 
  {
    "lab_name": "Sample Lab",
    "lab_address": "123 Lab Street, Labtown",
    "lab_mobile": "123-456-7890",
    "lab_email": "lab@example.com",
    "patient": {
      "name": "John Doe",
      "email": "john.doe@example.com",
      "contact_number": "987-654-3210",
      "age": 30,
      "registered_date": "2022-03-15",
      "reported_date": "2022-03-20",
      "received_by": "Dr. Smith"
    },
    "patient": {
      "first_name": "",
      "last_name": "Dehati",
      "address": "Raja Banglow Juriya ROad Lohardaga",
      "gender": "Male",
      "adhar_no": null,
      "mobile": "7462000521",
      "email": "dehatibabq007@gmail.com",
      "role": 3
  },
    "test_panel": [
      {
        "id": 1,
        "name": "Blood Test",
        "rate": 50.0
      },
      {
        "id": 2,
        "name": "Urine Test",
        "rate": 40.0
      }
    ],
    "total_fee": 90.0,
    "discount": 10.0
  }
  
  return (
    <div className="flex h-screen">
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex-1 lg:ml-64">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="p-6 bg-gray-50">
      <TestDatabaseComponent />
      </div>
    </div>
    </div>
  );
};

export default AddTestDatabase;