import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientRequest } from '../store/actions/patientActions';
import { PDFViewer, Document, PDFDownloadLink,Page, View, Text, StyleSheet, Image ,pdf} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 40,
    right: 40,
    textAlign: 'center',
    fontSize: 12,
    fontStyle: 'italic',
    color: 'gray',
  },
  signature: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    position: 'relative',
  },
  signatureItem: {
    textAlign: 'center',
    marginLeft: 10,
  },
  signatureImage: {
    width: 60,
    height: 'auto',
    marginBottom: 5,
  },
  signatureText: {
    fontSize: 12,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    marginBottom: 20,
    textAlign: 'center',
  },
  headerImage: {
    width: '100%',
    height: '40px',
    marginBottom: 5,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: 'purple',
    padding: 5,
    borderRadius: 5,
  },
  patientInfo: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  patientText: {
    fontSize: 12,
    marginBottom: 5,
  },
  interpretationText: {
    fontSize: 10,
    color: 'black',
    fontWeight: 'bold',
    padding: 10,
    border: 1,
    borderTopColor: 'gray',
  },
  table: {
    borderWidth: 0,
    width: '100%',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 20,
    fontSize: 10,
    padding: 5,
    margin: 0,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    padding: 5,
    textAlign: 'center',
    alignItems: 'center',
  },
  panelName: {
    backgroundColor: 'blue',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
    alignItems: 'center',
  },
  groupName: {
    fontWeight: 'bold',
    wordWrap: 'break-word',
    fontSize: 10,
    color: 'black',
    width: '25%',
    textAlign: 'center',
    alignItems: 'center',
  },
  tableCell: {
    flex: 1,
    wordWrap: 'break-word',
    padding: 5,
    width: '25%',
    textAlign: 'center',
    fontSize: 10,
    margin: 0,
  },
  testMethod: {
    fontSize: 8,
    padding: 3,
    color: 'gray',
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 12,
    fontStyle: 'italic',
  },
});

const PatientInfo = ({ patient, doctor_by, collection_date, reporting_date, age }) => (
  <View style={styles.patientInfo}>
    <View>
      <Text style={styles.patientText}>Name: {patient ? `${patient.first_name} ${patient.last_name}` : 'N/A'}</Text>
      <Text style={styles.patientText}>Age: {age ? age : 'N/A'}</Text>
      <Text style={styles.patientText}>Gender: {patient ? patient.gender : 'N/A'}</Text>
      <Text style={styles.patientText}>Mobile: {patient ? patient.mobile : 'N/A'}</Text>
    </View>
    <View style={{ textAlign: 'right' }}>
      <Text style={styles.patientText}>Referred By: {doctor_by || 'N/A'}</Text>
      <Text style={styles.patientText}>Collection Date: {collection_date ? new Date(collection_date).toLocaleDateString() : 'N/A'}</Text>
      <Text style={styles.patientText}>Reporting Date: {reporting_date ? new Date(reporting_date).toLocaleDateString() : 'N/A'}</Text>
    </View>
  </View>
);

const TestPanelReport = ({ panel }) => (
  <>
    {panel.test_panel_report && (
      <>
        <View style={[styles.tableRow, styles.tableHeader, { backgroundColor: 'purple', color: 'white' }]}>
          <Text style={[styles.tableCell, styles.fontBold]}>Test Name</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Observed Value</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Units</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Reference Range</Text>
        </View>
        <Text style={[styles.panelName, { marginVertical: 5 }]}>{panel.test_panel_report.name}</Text>
        {panel.test_panel_report.test_panel_report?.map((group, groupIndex) => (
          <React.Fragment key={groupIndex}>
            <Text style={[styles.groupName, { padding: 10, marginBottom: 10 }]}>{group.name}</Text>
            {group.report?.map((test, testIndex) => (
              <React.Fragment key={testIndex}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text style={styles.testName}>{test.name}</Text>
                    {test.method && <Text style={styles.testMethod}>Method: {test.method}</Text>}
                  </View>
                  <Text style={styles.tableCell}>{test.result}</Text>
                  <Text style={styles.tableCell}>{test.unit}</Text>
                  <Text style={styles.tableCell}>{test.normal_value}</Text>
                </View>
                {(test.interpretation || test.method) && (
                  <Text style={styles.interpretationText}>
                    <Text>Interpretation:</Text> {test.interpretation || 'N/A'}
                  </Text>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </>
    )}
    {panel.test_report && (
      <>
        <View style={[styles.tableRow, styles.tableHeader, { backgroundColor: 'purple', color: 'white' }]}>
          <Text style={[styles.tableCell, styles.fontBold]}>Test Name</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Observed Value</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Units</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Reference Range</Text>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCell}>
            <Text style={styles.testName}>{panel.test_report.name}</Text>
            {panel.test_report.method && <Text style={styles.testMethod}>Method: {panel.test_report.method}</Text>}
          </View>
          <Text style={styles.tableCell}>{panel.test_report.result}</Text>
          <Text style={styles.tableCell}>{panel.test_report.unit}</Text>
          <Text style={styles.tableCell}>{panel.test_report.normal_value}</Text>
        </View>
        {panel.test_report.interpretation && (
          <View style={styles.tableRow}>
            <Text style={styles.interpretationText}>
              <strong>Interpretation:</strong> {panel.test_report.interpretation}
            </Text>
          </View>
        )}
      </>
    )}
    {panel.test_group_report && (
      <>
        <View style={[styles.tableRow, styles.tableHeader, { backgroundColor: 'purple', color: 'white' }]}>
          <Text style={[styles.tableCell, styles.fontBold]}>Test Name</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Observed Value</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Units</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Reference Range</Text>
        </View>
        <Text style={[styles.groupName, { padding: 10, marginTop: 10 }]}>{panel.test_group_report.name}</Text>
        {panel.test_group_report.report?.map((test, testIndex) => (
          <React.Fragment key={testIndex}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.testName}>{test.name}</Text>
                {test.method && <Text style={styles.testMethod}>Method: {test.method}</Text>}
              </View>
              <Text style={styles.tableCell}>{test.result}</Text>
              <Text style={styles.tableCell}>{test.unit}</Text>
              <Text style={styles.tableCell}>{test.normal_value}</Text>
            </View>
            {test.interpretation && (
              <Text style={styles.interpretationText}>
                <strong>Interpretation:</strong> {test.interpretation || 'N/A'}
              </Text>
            )}
          </React.Fragment>
        ))}
      </>
    )}
  </>
);

const MyDocument = ({ patientData }) => {
  const {
    patient,
    test_panel,
    doctor_by,
    reporting_date,
    collection_date,
    lab_letterhead,
    lab_signature,
    age,
  } = patientData || {};
  
  return (
    <Document>
      {test_panel?.map((panel, index) => (
        <Page key={index} style={styles.page}>
          <View style={styles.section}>
            <View style={styles.header}>
              <Image src={lab_letterhead} style={styles.headerImage} />
              <Text style={styles.title}>Diagnosis Report</Text>
            </View>
            <PatientInfo
              patient={patient}
              doctor_by={doctor_by}
              collection_date={collection_date}
              reporting_date={reporting_date}
              age={age}
            />
            <TestPanelReport panel={panel} />
            <View style={styles.signature}>
              <View style={styles.signatureItem}>
                <Image src={lab_signature} style={styles.signatureImage} />
                <Text style={styles.signatureText}>Lab Incharge</Text>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

const DownloadReportView = () => {
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const patientState = useSelector((state) => state.patient);
  const { data: patientData, loading: patientLoading, error: patientError } = patientState;

  useEffect(() => {
    dispatch(fetchPatientRequest(caseId));
  }, [dispatch, caseId]);

  if (patientLoading) {
    return <div>Loading...</div>;
  }

  if (patientError) {
    return <div>Error: {patientError.message}</div>;
  }
  const handleDownload = async () => {
    const doc = <MyDocument patientData={patientData} />;
    const blob = await pdf(doc).toBlob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${caseId}.pdf`;
    a.click();
    URL.revokeObjectURL(url);
  };
  
  

  return (
    <>
      <div className="flex justify-center items-center h-screen">
    <div style={{ padding: '10px 20px' }}>
    <button onClick={handleDownload} className="px-2 py-2 rounded-sm bg-purple-500 mx-10">
          Download now!
        </button>
       
    </div>
  </div>
      
    
    </>
  );
};

export default DownloadReportView;
