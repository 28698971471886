import axios from 'axios';

// Define the base API URL as a constant
const Base_API_URL = 'https://api.lab2easy.com';

// Create a new Axios instance
const httpWithoutCommon = axios.create({
  baseURL: Base_API_URL,
  headers: {
    'Content-Type': 'application/json', // You can set default headers here
  },
});

export default httpWithoutCommon;