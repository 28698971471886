import React, { useState } from 'react';
import axios from 'axios';
import httpWithoutCommon from '../services/httpWithoutCommon';

const PasswordResetRequestForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await httpWithoutCommon.post('/reset-password/', { email }); // Use httpCommon.post
        // const response = await axios.post('http://127.0.0.1:8000/reset-password/', { email });
     
      setMessage(response.data.detail);
    } catch (error) {
        console.log(error)
      setMessage(error.response.data.email[0]);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded-lg max-w-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Trouble logging in?</h2>
<p className=' text-gray-800 text-lg text-wrap text-center px-8'>Enter your email and we'll send you a link to get back into your account.</p>
{message && <p className="text-red-500 text-center py-2 bg-gray-200">{message}</p>}
        <form onSubmit={handleSubmit} className=" space-y-10 mt-6">
          <div>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-2 border rounded shadow-sm"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600">Submit</button>
        </form>
       
      </div>
    </div>
  );
};

export default PasswordResetRequestForm;
