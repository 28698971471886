import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientRequest } from '../store/actions/patientActions';
import Navbar from '../components/Navbar';
import Header from '../components/Header';

const TestResult = ({ result, normalValue }) => {
  if (result === null || result === undefined || normalValue === null || normalValue === undefined) {
    return null;
  }
  // Extract min and max values from the normal range string
  const [min, max] = normalValue.split(' - ').map(parseFloat);
  const parsedResult = parseFloat(result);

  // Determine the classes based on the conditions
  const resultClass = parsedResult > max || parsedResult < min ? 'border-red-500 text-red-500' : 'border-green-500 text-green-500';

  return (
    <td className={`p-2 text-left sds block md:table-cell ${resultClass}`}>
      {result}
    </td>
  );
};

const ReportView = () => {
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const patientState = useSelector((state) => state.patient);
  const { data: patientData, loading: patientLoading, error: patientError } = patientState;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPatientRequest(caseId));
  }, [dispatch, caseId]);

  const handlePrint = () => {
    window.print();
  };

  if (patientLoading) {
    return <div>Loading...</div>;
  }

  if (patientError) {
    return <div>Error: {patientError.message}</div>;
  }


  const {
    lab_name,
    lab_address,
    email,
    phone,
    patient,
    test_panel,
    doctor_by,
    reporting_date,
    created_at,
    age,
    collection_date
  } = patientData || {};

  

  return (
    <>
    <div className="flex h-screen">
    <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
    <div className="flex-1 lg:ml-64">
      <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="p-6 bg-gray-50">
      <a
      href={`/view-report/${caseId}`}
      className="bg-green-500 text-white py-2 px-4 mb-6 text-right rounded  mt-2"
    >
      
      Print Report
    </a>
      <div className="bg-blue-600 text-white text-center py-4 mt-4 mb-6 rounded">
        <h1 className="text-xl font-bold">Diagnosis Report</h1>
      </div>
      <div className="grid grid-cols-2 gap-10 mb-6 ">
        <div>
          <h2 className="text-lg font-bold">Patient Information</h2>
          <p>
  Name: {patient ? (patient.first_name || patient.last_name ? `${patient.first_name || ''} ${patient.last_name || ''}`.trim() : '') : ''}
</p>
          <p>Age: {age ? age:""} </p>
          <p>Gender: {patient ? patient.gender : 'N/A'}</p>
          <p>Mobile: {patient ? patient.mobile : 'N/A'}</p>
          
        </div>
        <div className=' justify-end text-right'>
            <p>Referred By: {doctor_by || 'N/A'}</p>
            <p>Collection Date: {collection_date ? new Date(collection_date).toLocaleDateString() : 'N/A'}</p>
            <p>Reporting Date: {reporting_date ? new Date(reporting_date).toLocaleDateString() : 'N/A'}</p>
        </div>
      </div>
      <div>
        <h2 className="text-lg font-bold mb-4">Test Panels</h2>
        <table className="min-w-full border-collapse block md:table">
          <thead className="block md:table-header-group bg-blue-900">
            <tr className="border border-gray-300  md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto md:relative ">
              <th className=" p-2 text-gray-100 font-bold text-left block md:table-cell">Test Name</th>
              <th className=" p-2 text-gray-100 font-bold text-left block md:table-cell">Result</th>
              <th className=" p-2 text-gray-100 font-bold text-left block md:table-cell">Unit</th>
              <th className=" p-2 text-gray-100 font-bold text-left block md:table-cell">References</th>
            </tr>
          </thead>
          <tbody className="block md:table-row-group">
            {test_panel?.map((panel, index) => (
              
              <React.Fragment key={index}>
                
                {panel.test_panel_report && (
                  
                  <>
                 
                 <tr className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                      <td className="p-2 block md:table-cell text-center capitalize underline text-xl font-bold text-balck" colSpan="4">{panel.test_panel_report.category}</td>
                    </tr>
                  
                    <tr className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                      <td className="p-2 text-left block md:table-cell font-bold text-purple-700" colSpan="4">{panel.test_panel_report.name}</td>
                    </tr>
                    {panel.test_panel_report.test_panel_report?.map((group, groupIndex) => (
  <React.Fragment key={groupIndex}>
    <tr className="bg-gray-50 border border-gray-200 md:border-none block md:table-row">
      <td className="p-2 text-left block md:table-cell font-bold text-purple-500 pl-4" colSpan="4">{group.name}</td>
    </tr>
    {group.report?.map((test, testIndex) => (
      <React.Fragment key={testIndex}>
        <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
          <td className="p-2 text-left block md:table-cell pl-8">{test.name}</td>
          <TestResult result={test.result} normalValue={test.normal_value} />
          <td className="p-2 text-left block md:table-cell">{test.unit}</td>
          <td className="p-2 text-left block md:table-cell">{test.normal_value}</td>
        </tr>
        {test.interpretation || test.method ? (
          <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
            <td className="p-2 text-left block md:table-cell pl-8" colSpan="4">
              <strong>Interpretation:</strong> {test.interpretation || 'N/A'}, <strong>Method:</strong> {test.method || 'N/A'}
            </td>
          </tr>
        ) : null}
      </React.Fragment>
    ))}
  </React.Fragment>
))}
                    <tr className="bg-gray-50 border border-gray-200 md:border-none block md:table-row">
                      <td className="p-2 text-left block md:table-cell font-bold text-purple-500 pl-4" colSpan="4">Individual Tests</td>
                    </tr>
                    {panel.test_panel_report.test_report?.map((test, testIndex) => (
                      <React.Fragment key={testIndex}>
                      <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                        <td className="p-2 text-left block md:table-cell pl-8">{test.name}</td>
                        <TestResult result={test.result} normalValue={test.normal_value} />
                        <td className="p-2 text-left block md:table-cell">{test.unit}</td>
                        <td className="p-2 text-left block md:table-cell">{test.normal_value}</td>
                      </tr>
                      {test.interpretation || test.method ? (
                        <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                          <td className="p-2 text-left block md:table-cell pl-8" colSpan="4">
                            <strong>Interpretation:</strong> {test.interpretation || 'N/A'}, <strong>Method:</strong> {test.method || 'N/A'}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                    ))}
                  </>
                )}
                {panel.test_report && (
  <React.Fragment key={panel.test_report.id}>
    <tr className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                      <td className="p-2 block md:table-cell text-center capitalize underline text-xl font-bold text-balck" colSpan="4">{panel.test_report.category}</td>
                    </tr>
  <tr className="bg-white border border-gray-300 md:border-none block md:table-row">
    <td className="p-2 text-left block md:table-cell font-bold text-green-700"><div>{panel.test_report.name}</div>
        
        {panel.test_report.method  ? (
            <div>
    <strong>Method:</strong> {panel.test_report.method || 'N/A'} 
    </div>
 ) : null}
        
    </td>
    <TestResult result={panel.test_report.result} normalValue={panel.test_report.normal_value} />
    <td className="p-2 text-left block md:table-cell">{panel.test_report.unit}</td>
    <td className="p-2 text-left block md:table-cell">{panel.test_report.normal_value}</td>
  </tr>
  {panel.test_report.interpretation  ? (
   <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
     <td className="p-2 text-left block md:table-cell pl-8" colSpan="4">
       <strong>Interpretation:</strong> {panel.test_report.interpretation || 'N/A'} 
     </td>
   </tr>
 ) : null}
  

</React.Fragment>
)}



                {panel.test_group_report && (
                  <>
                  <tr className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                      <td className="p-2 block md:table-cell text-center capitalize underline text-xl font-bold text-balck" colSpan="4">{panel.test_group_report.category}</td>
                    </tr>
                  
                    <tr className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                      <td className="p-2 text-left block md:table-cell  font-bold text-blue-700" colSpan="4">{panel.test_group_report.name}</td>
                    </tr>
                    {panel.test_group_report.report?.map((test, testIndex) => (
                       <React.Fragment key={testIndex}>
                        
                       <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                         <td className="p-2 text-left block md:table-cell pl-8">{test.name}</td>
                         <TestResult result={test.result} normalValue={test.normal_value} />
                         <td className="p-2 text-left block md:table-cell">{test.unit}</td>
                         <td className="p-2 text-left block md:table-cell">{test.normal_value}</td>
                       </tr>
                       {test.interpretation || test.method ? (
                        <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                          <td className="p-2 text-left block md:table-cell pl-8" colSpan="4">
                            <strong>Interpretation:</strong> {test.interpretation || 'N/A'}, <strong>Method:</strong> {test.method || 'N/A'}
                          </td>
                        </tr>
                      ) : null}
                       
                  
                     </React.Fragment>
                   ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-center font-bold mt-6">
        <p>**** End of the Report ****</p>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default ReportView;
