// Action Types
export const ADD_REFERRED_BY_REQUEST = 'ADD_REFERRED_BY_REQUEST';
export const ADD_REFERRED_BY_SUCCESS = 'ADD_REFERRED_BY_SUCCESS';
export const ADD_REFERRED_BY_FAILURE = 'ADD_REFERRED_BY_FAILURE';

export const FETCH_REFERRED_BY_REQUEST = 'FETCH_REFERRED_BY_REQUEST' ;
export const FETCH_REFERRED_BY_SUCCESS = 'FETCH_REFERRED_BY_SUCCESS' ;
export const FETCH_REFERRED_BY_FAILURE = 'FETCH_REFERRED_BY_FAILURE';

export const fetchReferredByRequest = () => ({
  type: FETCH_REFERRED_BY_REQUEST,
});

export const fetchReferredBySuccess = (referredByOptions) => ({
  type: FETCH_REFERRED_BY_SUCCESS,
  payload: referredByOptions,
});

export const fetchReferredByFailure = (error) => ({
  type: FETCH_REFERRED_BY_FAILURE,
  payload: error,
});

export const addReferredByRequest = (newReferredBy) => ({
  type: ADD_REFERRED_BY_REQUEST,
  payload: newReferredBy,
});

export const addReferredBySuccess = (newReferredBy) => ({
  type: ADD_REFERRED_BY_SUCCESS,
  payload: newReferredBy,
});

export const addReferredByFailure = (error) => ({
  type: ADD_REFERRED_BY_FAILURE,
  payload: error,
});