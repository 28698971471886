// sagas/testDatabaseSaga.js

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_TEST_DATABASE_REQUEST,
  fetchTestDatabaseSuccess,
  fetchTestDatabaseFailure,
  ADD_TEST_DATABASE_REQUEST,
  addTestDatabaseSuccess,
  addTestDatabaseFailure,ADD_NORMAL_VALUE_REQUEST,
  addNormalValueSuccess,
  addNormalValueFailure,fetchNormalValueRequest,fetchNormalValueSuccess,fetchNormalValueFailure, FETCH_NORMAL_VALUE_REQUEST
} from  '../actions/testDatabaseAction';
import { ADD_REFERRED_BY_REQUEST, FETCH_REFERRED_BY_REQUEST, addReferredByFailure, addReferredBySuccess, fetchReferredByFailure, fetchReferredBySuccess } from '../actions/doctorAction';
import httpCommon from '../../services/httpCommon';

// API Calls
const fetchDoctorAPI = () => {
  return httpCommon.get('/doctors/');
};


const addDoctorAPI = (testDatabaseData) => {
  return httpCommon.post('/doctors/', testDatabaseData);
};



// Sagas
function* fetchDoctorSaga() {
  try {
    const response = yield call(fetchDoctorAPI);
    console.log(response.data)
    yield put(fetchReferredBySuccess(response.data));
  } catch (error) {
    yield put(fetchReferredByFailure(error));
  }
}


function* addDoctorSaga(action) {
  try {
    const response = yield call(addDoctorAPI, action.payload);
    yield put(addReferredBySuccess(response.data));
  } catch (error) {
    yield put(addReferredByFailure(error));
  }
}

// Watchers
export function* watchFetchDoctor() {
  yield takeLatest(FETCH_REFERRED_BY_REQUEST, fetchDoctorSaga);
}

export function* watchAddDoctor() {
  yield takeLatest(ADD_REFERRED_BY_REQUEST, addDoctorSaga);
}

  
  // Watcher

