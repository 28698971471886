import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNormalValueRequest, fetchNormalValueRequest } from '../store/actions/testDatabaseAction';
import { useParams } from 'react-router-dom';

const AddNormalValueComponent = () => {
    const dispatch = useDispatch();
    const { testId } = useParams(); // Fetching the testId from the URL params
    const [normalValues, setNormalValues] = useState([]);
    const testNormalValueState = useSelector((state) => state.testNormalValue);

    const { normal_value_data: testNormalValues, loading: testNormalValuesLoading } = testNormalValueState;

    const handleAddNormalValue = () => {
        if (normalValues.length > 0) {
            dispatch(addNormalValueRequest(testId, normalValues));
            setNormalValues([]); // Reset the form values after adding
            

            
        }
    };

    const handleInputChange = (index, key, value) => {
        const updatedNormalValues = [...normalValues];
        updatedNormalValues[index][key] = value;
        setNormalValues(updatedNormalValues);
    };

    const handleRemoveNormalValue = (index) => {
        const updatedNormalValues = [...normalValues];
        updatedNormalValues.splice(index, 1);
        setNormalValues(updatedNormalValues);
    };

    const handleAddMoreNormalValue = () => {
        const newIndex = normalValues.length; // Calculate the new index
        const newNormalValue = {}; // Create a new normal value object with index
        setNormalValues([...normalValues, newNormalValue]); // Add the new normal value
    };

    useEffect(() => {
        // Fetch normal values for the selected test ID
        dispatch(fetchNormalValueRequest(testId));
    }, [dispatch, testId]);

    useEffect(() => {
        // Update normalValues state when testNormalValues changes
        if (testNormalValues) {
            setNormalValues(testNormalValues);
        }
    }, [testNormalValues]);
    useEffect(() => {
        // Update normalValues state when testNormalValues changes
        dispatch(fetchNormalValueRequest(testId));
    }, []);

    return (
        <div className="container mx-auto py-4">
            <div className="py-4">
                <h2 className="text-xl font-bold mb-4">Add Normal Values for Test ID: {testId}</h2>
                {normalValues.map((normalValue, index) => (
                    <div key={index} className="grid grid-cols-6 gap-4 mb-4">
                        <input
                            type="text"
                            placeholder="Gender"
                            value={normalValue.gender || ''}
                            onChange={(e) => handleInputChange(index, 'gender', e.target.value)}
                            className="col-span-1 border border-gray-300 rounded-md px-3 py-2"
                        />
                        
                            <input
                                type="number"
                                placeholder="Min Age"
                                value={normalValue.min_age || ''}
                                onChange={(e) => handleInputChange(index, 'min_age', e.target.value)}
                                className="col-span-1 border border-gray-300 rounded-md px-3 py-2"
                            />
                            <input
                                type="number"
                                placeholder="Max Age"
                                value={normalValue.max_age || ''}
                                onChange={(e) => handleInputChange(index, 'max_age', e.target.value)}
                                className="col-span-1 border border-gray-300 rounded-md px-3 py-2"
                            />
                            <input
                                type="number"
                                placeholder="Min Value"
                                value={normalValue.min_value || ''}
                                onChange={(e) => handleInputChange(index, 'min_value', e.target.value)}
                                className="col-span-1 border border-gray-300 rounded-md px-3 py-2"
                            />
                            <input
                                type="number"
                                placeholder="Max Value"
                                value={normalValue.max_value || ''}
                                onChange={(e) => handleInputChange(index, 'max_value', e.target.value)}
                                className="col-span-1 border border-gray-300 rounded-md px-3 py-2"
                            />
                            
                        {/* Add other input fields as per your requirement */}
                        <button
                            onClick={() => handleRemoveNormalValue(index)}
                            className="col-span-1 bg-red-500 text-white font-semibold py-2 rounded-md"
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button
                    onClick={handleAddMoreNormalValue}
                    className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-md"
                >
                    + Add More
                </button>
                <button
                    onClick={handleAddNormalValue}
                    className="bg-blue-700 text-white font-semibold py-2 px-4 rounded-md ml-4"
                >
                    Add Normal Value
                </button>
            </div>
        </div>
    );
};

export default AddNormalValueComponent;
