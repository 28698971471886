import React, { useEffect, useState } from 'react';
import { AiFillCalendar } from 'react-icons/ai';
import AddReferredByModal from './AddReferredByModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReferredByRequest } from '../store/actions/doctorAction';
import { fetchCollectionAgentsRequest } from '../store/actions/collectionAgentAction';
import { fetchCollectionCentersRequest } from '../store/actions/collectionCenterAction';
import LabSection from './LabSection';

const EditCaseForm = ({ caseData, setCaseData }) => {
    
  const dispatch = useDispatch();
  const [referredBy, setReferredBy] = useState('');
  const [collectionCenter, setCollectionCenter] = useState('');
  const [collectionDate, setCollectionDate] = useState('');
  const [reportingDate, setReportingDate] = useState('');
  const [generateReportOnline, setGenerateReportOnline] = useState(false);
  const [agent, setAgent] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showLab, setShowLab] = useState(false);
  const doctorState = useSelector((state) => state.doctor);
  const agentState = useSelector((state) => state.agent);
  const centerState = useSelector((state) => state.center);

  const { data: doctors } = doctorState;
  const { data: agents } = agentState;
  const { data: centers } = centerState;

  useEffect(() => {
    setReferredBy(caseData.referredBy || '');
    setCollectionCenter(caseData.collectionCenter || '');
    setCollectionDate(caseData.collectionDate || '');
    setReportingDate(caseData.reportingDate || '');
    setGenerateReportOnline(caseData.generateReportOnline || false);
    setAgent(caseData.agent || '');
  }, [caseData]);

  useEffect(() => {
    dispatch(fetchReferredByRequest());
    dispatch(fetchCollectionAgentsRequest());
    dispatch(fetchCollectionCentersRequest());
  }, [dispatch, showModal]);
  const isoToDateTimeLocal = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0'); // Month is zero-indexed
    const day = `${date.getDate()}`.padStart(2, '0');
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  
  // Usage:
  const formattedCollectionDate = isoToDateTimeLocal(collectionDate);
  const formattedReportingDate = isoToDateTimeLocal(reportingDate);
  const handleReferredByChange = (e) => {
    alert(e.target.value)
    setReferredBy(e.target.value);
  };

  const handleCollectionCenterChange = (e) => {
    alert("kfjdkjfk")
    setCollectionCenter(e.target.value);
  };

  const handleAgentChange = (e) => {
    setAgent(e.target.value);
  };

  const handleCollectionDateChange = (e) => {
    setCollectionDate(e.target.value);
  };

  const handleReportingDateChange = (e) => {
    setReportingDate(e.target.value);
  };

  const handleGenerateReportOnlineChange = (e) => {
    setGenerateReportOnline(e.target.checked);
  };

  const handleModalClose = () => {
    dispatch(fetchReferredByRequest());
    setShowModal(false);
  };

  const handleDoctorModalOpen = () => {
    setShowLab(false);
    setShowModal(true);
    dispatch(fetchReferredByRequest());
  };

  const handleLabModalOpen = () => {
    setShowLab(true);
    setShowModal(false);
  };
  useEffect(() => {
    setCaseData({ collectionDate, reportingDate, referredBy, agent, collectionCenter}); // Include paymentType in payment data
  }, [collectionDate, reportingDate, referredBy, agent, collectionCenter]);

  return (
    <section className="add-case">
      <div className="w-full mx-auto bg-blue-100 shadow-md rounded px-8 pt-6 pb-8 my-4">
        <h2 className="text-2xl font-bold mb-4 text-blue-800">Edit Case Detail</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 py-4 px-8 shadow-2xl">
          <div className="mb-4 col-span-2">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="referredBy">
              Referred By
            </label>
            <div className="flex mx-auto px-2 items-center">
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                name="referredBy"
                value={referredBy}
                onChange={handleReferredByChange}
              >
                <option value="">Select Referred By</option>
                {doctors.map((doctor) => (
                  <option key={doctor.id} value={doctor.id}>
                    {`${doctor.first_name} ${doctor.last_name} - ${doctor.email}`}
                  </option>
                ))}
              </select>
              <button
                className="bg-white border border-blue-500 hover:bg-blue-600 w-1/6 text-blue-500 hover:text-white font-bold px-4 rounded focus:outline-none focus:shadow-outline text-sm"
                onClick={handleDoctorModalOpen}
              >
                +New
              </button>
            </div>
            {showModal && <AddReferredByModal onClose={handleModalClose} />}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="collectionCenter">
              Collection Center
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="collectionCenter"
              value={collectionCenter}
              onChange={handleCollectionCenterChange}
            >
              <option value="">Select Collection Center</option>
              {centers.map((center) => (
                <option key={center.id} value={center.id}>
                  {`${center.name}- ${center.email}`}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="agent">
              Agent
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="agent"
              value={agent}
              onChange={handleAgentChange}
            >
              <option value="">Select Agent</option>
              {agents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {`${agent.first_name} ${agent.last_name} - ${agent.email}`}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="collectionDate">
              Collection Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="collectionDate"
              type="datetime-local"
              name="collectionDate"
              value={formattedCollectionDate}
              onChange={handleCollectionDateChange}
              placeholder="Select Collection Date"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reportingDate">
              Reporting Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="reportingDate"
              type="datetime-local"
              name="reporting_date_time"
              value={formattedReportingDate}
              onChange={handleReportingDateChange}
              placeholder="Select Reporting Date and Time"
            />
          </div>
          <div className="col-span-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="generateReportOnline"
                checked={generateReportOnline}
                onChange={handleGenerateReportOnlineChange}
                className="form-checkbox mr-2"
              />
              <span className="text-gray-700">Generate Report Online</span>
            </label>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default EditCaseForm;

