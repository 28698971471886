import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientRequest, uploadPdfRequest } from '../store/actions/patientActions';
import { PDFViewer, PDFDownloadLink, Document, Page, View, Text, StyleSheet, Image, pdf,Font  } from '@react-pdf/renderer';
import { FaWhatsapp } from 'react-icons/fa';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import QRCode from 'react-qr-code';
import QRCodeComponent from '../components/QRCodeImage';
import QRCodeImage from '../components/QRCodeImage';



const TestResult = ({ result, normalValue }) => {
  if (result === null || result === undefined || normalValue === null || normalValue === undefined) {
    return null;
  }

  const [min, max] = normalValue.split(' - ').map(parseFloat);
  const parsedResult = parseFloat(result);
  const resultStyle = parsedResult > max || parsedResult < min ? styles.redText : styles.greenText;

  return (
    <Text style={[styles.tableCell, resultStyle]}>
      {result}
    </Text>
  );
};
Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFUZ0b.woff2',
      fontWeight: 'normal',
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VeJoCqeDjg.woff2',
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  centeredText: {
    textAlign: 'center',
    paddingVertical: 10,
    textDecorationLine: 'underline',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 40,
    right: 40,
    textAlign: 'center',
    fontSize: 12,
    fontStyle: 'italic',
    color: 'gray',
  },
  redText: {
    color: 'red',
    backgroundColor: 'grey',
    paddingHorizontal: 15,
  },
  signature: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    position: 'relative',
  },
  signatureItem: {
    textAlign: 'center',
    marginLeft: 10,
  },
  signatureImage: {
    width: 60,
    height: 'auto',
    marginBottom: 5,
  },
  signatureText: {
    fontSize: 12,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    marginBottom: 20,
    textAlign: 'center',
  },
  headerImage: {
    width: '100%',
    height: '40px',
    marginBottom: 5,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: 'purple',
    padding: 5,
    borderRadius: 5,
  },
  patientInfo: {
    marginBottom: 20,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  patientText: {
    fontSize: 10,
    marginBottom: 5,
  },
  patientNameText: {
    fontSize: 18,
    fontWeight: 'bold', // This will make the text bold
    marginBottom: 5,
  },
  interpretationText: {
    fontSize: 10,
    color: 'black',
    fontWeight: 'bold',
    padding: 10,
    border: 1,
    borderTopColor: 'gray',
  },
  table: {
    borderWidth: 0,
    width: '100%',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 20,
    fontSize: 10,
    padding: 5,
    margin: 0,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    padding: 5,
    textAlign: 'center',
    alignItems: 'center',
    borderColor: 'gray',
    borderWidth: 1,
  },
  panelName: {
    backgroundColor: 'gray',
    fontWeight: 'bold',
    paddingVertical: 10,
    wordWrap: 'break-word',
    fontSize: 12,
    color: 'black',
    textAlign: 'center',
    alignItems: 'center',
  },
  groupName: {
    fontWeight: 'bold',
    wordWrap: 'break-word',
    fontSize: 10,
    color: 'black',
    width: '25%',
    textAlign: 'center',
    alignItems: 'center',
  },
  tableCell: {
    flex: 1,
    wordWrap: 'break-word',
    padding: 5,
    width: '25%',
    textAlign: 'center',
    fontSize: 10,
    margin: 0,
  },
  testMethod: {
    fontSize: 8,
    padding: 3,
    color: 'gray',
  },
  column: {
    
    padding: 5,
    borderRight: 0.5,
    borderColor: 'black',
  },

});

const PatientInfo = ({ patient, doctor_by, collection_date, reporting_date, age, qr_image_url }) => (
  <View style={styles.patientInfo}>
    {/* First Column */}
    <View style={[styles.column,{width: '30%'}]}>
      <Text style={styles.patientNameText}>
        {patient ? (patient.first_name || patient.last_name ? `${patient.first_name || ''} ${patient.last_name || ''}`.trim() : '') : ''}
      </Text>
      <Text style={styles.patientText}>Age: {age ? age : 'N/A'}</Text>
      <Text style={styles.patientText}>Gender: {patient ? patient.gender : 'N/A'}</Text>
      <Text style={styles.patientText}>Mobile: {patient ? patient.mobile : 'N/A'}</Text>
      
    </View>
    <View style={[styles.column,{width: '30%'}]}>
    {qr_image_url && (
        <View style={styles.qrCodeContainer}>
          <Image src={qr_image_url} style={styles.signatureImage} />
        </View>
      )}
    </View>


    {/* Third Column */}
    <View style={[styles.column,{width: '30%'}]}>
      <Text style={styles.patientText}>Referred By: {doctor_by || 'N/A'}</Text>
      <Text style={styles.patientText}>
        Collection Date: {collection_date ? new Date(collection_date).toLocaleDateString() : 'N/A'}
      </Text>
      <Text style={styles.patientText}>
        Reporting Date: {reporting_date ? new Date(reporting_date).toLocaleDateString() : 'N/A'}
      </Text>
    </View>
  </View>
);

const TestPanelReport = ({ panel }) => (
  <>
    {panel.test_panel_report && (
      <>
        <View>
          <Text style={[styles.centeredText]}>{panel.test_panel_report.category}</Text>
        </View>
        <View style={[styles.tableRow, styles.tableHeader, { backgroundColor: 'white', color: 'black' }]}>
          <Text style={[styles.tableCell, styles.fontBold]}>Test Name</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Observed Value</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Units</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Reference Range</Text>
        </View>
        <Text style={[styles.panelName, { marginVertical: 10 }]}>{panel.test_panel_report.name}</Text>
        {panel.test_panel_report.test_panel_report?.map((group, groupIndex) => (
          <React.Fragment key={groupIndex}>
            <Text style={[styles.groupName, { padding: 10, marginBottom: 10 }]}>{group.name}</Text>
            {group.report?.map((test, testIndex) => (
              <React.Fragment key={testIndex}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text style={styles.testName}>{test.name}</Text>
                    {test.method && <Text style={styles.testMethod}>Method: {test.method}</Text>}
                  </View>
                  <TestResult result={test.result} normalValue={test.normal_value} />
                  <Text style={styles.tableCell}>{test.unit}</Text>
                  <Text style={styles.tableCell}>{test.normal_value}</Text>
                </View>
                {test.interpretation && (
                  <Text style={styles.interpretationText}>
                    <Text>Interpretation:</Text> {test.interpretation || 'N/A'}
                  </Text>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </>
    )}
    {panel.test_report && (
      <>
        <View>
          <Text style={[styles.centeredText]}>{panel.test_report.category}</Text>
        </View>
        <View style={[styles.tableRow, styles.tableHeader, { backgroundColor: 'white', color: 'black' }]}>
          <Text style={[styles.tableCell, styles.fontBold]}>Test Name</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Observed Value</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Units</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Reference Range</Text>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCell}>
            <Text style={styles.testName}>{panel.test_report.name}</Text>
            {panel.test_report.method && <Text style={styles.testMethod}>Method: {panel.test_report.method}</Text>}
          </View>
          <TestResult result={panel.test_report.result} normalValue={panel.test_report.normal_value} />
          <Text style={styles.tableCell}>{panel.test_report.unit}</Text>
          <Text style={styles.tableCell}>{panel.test_report.normal_value}</Text>
        </View>
        {panel.test_report.interpretation && (
          <View style={styles.tableRow}>
            <Text style={styles.interpretationText}>
              <strong>Interpretation:</strong> {panel.test_report.interpretation}
            </Text>
          </View>
        )}
      </>
    )}
    {panel.test_group_report && (
      <>
        <View>
          <Text style={[styles.centeredText]}>{panel.test_group_report.category}</Text>
        </View>
        <View style={[styles.tableRow, styles.tableHeader, { backgroundColor: 'white', color: 'black' }]}>
          <Text style={[styles.tableCell, styles.fontBold]}>Test Name</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Observed Value</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Units</Text>
          <Text style={[styles.tableCell, styles.fontBold]}>Reference Range</Text>
        </View>
        <Text style={[styles.groupName, { padding: 10, marginTop: 10 }]}>{panel.test_group_report.name}</Text>
        {panel.test_group_report.report?.map((test, testIndex) => (
          <React.Fragment key={testIndex}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.testName}>{test.name}</Text>
                {test.method && <Text style={styles.testMethod}>Method: {test.method}</Text>}
              </View>
              <TestResult result={test.result} normalValue={test.normal_value} />
              <Text style={styles.tableCell}>{test.unit}</Text>
              <Text style={styles.tableCell}>{test.normal_value}</Text>
            </View>
            {test.interpretation && (
              <Text style={styles.interpretationText}>
                <strong>Interpretation:</strong> {test.interpretation || 'N/A'}
              </Text>
            )}
          </React.Fragment>
        ))}
      </>
    )}
  </>
);

const MyDocument = ({ patientData }) => {
  const {
    patient,
    test_panel,
    doctor_by,
    reporting_date,
    collection_date,
    lab_letterhead,
    lab_signature,
    age,
    qr_image_url

  } = patientData || {};

  return (
    <Document>
      {test_panel?.map((panel, index) => (
        <Page key={index} style={styles.page}>
          <View style={styles.section}>
            {lab_letterhead && (
              <View style={styles.header}>
                <Image src={lab_letterhead} style={styles.headerImage} />
               
              </View>
            )}
            
            <PatientInfo
              patient={patient}
              doctor_by={doctor_by}
              collection_date={collection_date}
              reporting_date={reporting_date}
              age={age}
              qr_image_url={qr_image_url}
            />
            <TestPanelReport panel={panel} />
            {lab_signature && (
              <View style={styles.signature}>
                <View style={styles.signatureItem}>
                  <Image src={lab_signature} style={styles.signatureImage} />
                  <Text style={styles.signatureText}>Lab Incharge</Text>
                </View>
              </View>
            )}
          </View>
        </Page>
      ))}
    </Document>
  );
};

const ReportView = () => {
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const patientState = useSelector((state) => state.patient);
  const { data: patientData, loading: patientLoading, error: patientError } = patientState;
  const [pdfBlob, setPdfBlob] = useState(null);

  useEffect(() => {
    dispatch(fetchPatientRequest(caseId));
  }, [dispatch, caseId]);

  useEffect(() => {
    if (patientData) {
      const doc = <MyDocument patientData={patientData} />;
      
      const generatePdf = async () => {
        const blob = await pdf(doc).toBlob();
        setPdfBlob(blob);
        await dispatch(uploadPdfRequest(caseId, pdfBlob));
      };
      console.log(pdfBlob)
      generatePdf();
    }
  }, [patientData]);

  const handlePdfUpload = async () => {
    if (!pdfBlob) {
      alert('Please generate the PDF first.');
      return;
    }
  
    try {
      await dispatch(uploadPdfRequest(caseId, pdfBlob));
      const reload = window.confirm('Do you want to reload the page?');
      if (true) {
        window.location.reload();
      }
      
    } catch (error) {
      console.error('Upload failed:', error);
      alert('Failed to upload PDF. Please try again.');
    }
  };
  

  const handleSendWhatsAppMessage = async () => {
    if (!pdfBlob) {
      alert('Please generate the PDF first.');
      return;
    }

    try {
      
      const fileURL = patientData.patient_report_pdf;
      const phoneNumber = '91' + patientData.patient.mobile;
      const instructionMessage = `Please find the attached PDF report. You can download it from the following link: ${fileURL}. Please manually attach the downloaded PDF when you send the message via WhatsApp.`;
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(instructionMessage)}`;
      window.open(whatsappURL, '_blank');
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
      alert('Failed to send WhatsApp message.');
    }
  };

  if (patientLoading) {
    return <div>Loading...</div>;
  }

  if (patientError) {
    return <div>Error: {patientError.message}</div>;
  }

  return (
    <>
    <div className="flex h-screen">
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex-1 lg:ml-64">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      <div style={{ padding: '10px 20px' }}>
        <button className='px-2 py-2 rounded-sm bg-purple-500' onClick={() => window.history.back()}>Back</button>
        {patientData?.is_report_generated && (
          <>
          <button
          className='px-2 py-2 rounded-sm bg-purple-500 mx-10'
          onClick={() => window.open(patientData?.patient_report_pdf, '_blank')}
        >
          Download now!
        </button>
        <button
        onClick={handleSendWhatsAppMessage}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2  px-4 rounded items-center space-x-2 focus:outline-none focus:shadow-outline"
        style={{ marginTop: '10px', cursor: 'pointer' }}
      >
        <span className='flex'><FaWhatsapp className="text-white" style={{ width: '1.5em', height: '1.5em' }} />
        Send Report</span>
      </button></>
            
          )}
        
        {pdfBlob && !patientData.is_report_generated && (
          <button
            className='px-2 py-2 rounded-sm bg-purple-500 mx-10'
            onClick={handlePdfUpload}
          >
            Generate Report
          </button>
        )}
       
        
      </div>

      <PDFViewer style={{ width: '100%', height: 'calc(100vh - 110px)' }}>
        <MyDocument patientData={patientData} />
      </PDFViewer>
      </div></div>
    </>
  );
};

export default ReportView;
