import { call, put, takeLatest } from 'redux-saga/effects';
import { addPatientSuccess, addPatientFailure, fetchPatientSuccess, fetchPatientFailure, FETCH_PATIENT_REQUEST,
  FETCH_NEXT_PATIENT_LIST_REQUEST,fetchPatientListSuccess, fetchPatientListFailure, FETCH_PATIENT_LIST_REQUEST, updatePatientSuccess, updatePatientFailure, UPDATE_PATIENT_REQUEST, uploadPdfSuccess, uploadPdfFailure, UPLOAD_PDF_REQUEST } from '../actions/patientActions';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from '../../services/utils';
import httpCommon from '../../services/httpCommon';


const fetchPatientAPI = (caseId) => {
  return httpCommon.get(`/patient-case-entry/${caseId}/`);
};







const fetchPatientListAPI = () => {
  console.log("dsflakldskfldsklfksdl")
  return httpCommon.get('/add-patient/');
};
const fetchPatientNextListAPI = (url) => {

  console.log("dsflakldskfldsklfksdl---->>>>>>",url)
  return httpCommon.get(url);
};


export const addPatientApi = async (data) => {
  try {
    const response = await httpCommon.post('/add-patient/', data);
   
    return response.data;
    
    
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updatePatientApi = async (caseId,data) => {
  try {
    const response = await httpCommon.put(`/patient-case/${caseId}/`, data);
    console.log("api", response.data)
   
    return response.data;
    
    
  } catch (error) {
    throw new Error(error.message);
  }
};

function* addPatient(action) {
  try {
    const response = yield call(addPatientApi, action.payload);
    if (response.success){
      yield put(addPatientSuccess(response.data));

    }else{
      const err = errorMessage(response.error)
      yield put(addPatientFailure(err));
    }
    
   
    
  } catch (error) {
    yield put(addPatientFailure(error.message));
  }
}

function* fetchPatientSaga(action) {
  try {
    const caseId  = action.payload;
    const response = yield call(fetchPatientAPI,caseId);
    yield put(fetchPatientSuccess(response.data));
  } catch (error) {
    yield put(fetchPatientFailure(error));
  }
}
function* fetchPatientListSaga(action) {
  try {
   
    const response = yield call(fetchPatientListAPI);
    console.log(response)
    yield put(fetchPatientListSuccess(response.data));
  } catch (error) {
    yield put(fetchPatientListFailure(error));
  }
}
function* fetchNextPatientListSaga(action) {
  try {

      const response = yield call(fetchPatientNextListAPI, action.payload);
      
      yield put(fetchPatientListSuccess(response.data));
  } catch (error) {
      yield put(fetchPatientListFailure(error.message));
  }
}
function* updatePatientSaga(action) {
  try {
    const { caseId, patientData } = action.payload;
    const response = yield call(updatePatientApi, caseId, patientData);
    yield put(updatePatientSuccess(response));
  } catch (error) {
    console.log("sahgasaAS error-->", error);
    yield put(updatePatientFailure(error.message));
  }
}

function* uploadPdf( action ) {
  const { caseId, pdfBlob } = action.payload;

  try {
    const formData = new FormData();
    formData.append('report_pdf', pdfBlob, `${caseId}.pdf`);
    formData.append('case_id', caseId);
    formData.append('is_report_generated', true);
    console.log(formData)

    const response = yield call(httpCommon.post, `/generate-report-pdf/${caseId}/update/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      yield put(uploadPdfSuccess());
    } else {
      yield put(uploadPdfFailure('Failed to upload PDF file.'));
    }
  } catch (error) {
    yield put(uploadPdfFailure(error.message));
  }
}

export function* watchAddPatient() {
  yield takeLatest('ADD_PATIENT_REQUEST', addPatient);
}
export function* watchUploadPdf() {
  yield takeLatest(UPLOAD_PDF_REQUEST, uploadPdf);
}
export function* watchFetchPatient() {
  yield takeLatest('FETCH_PATIENT_REQUEST', fetchPatientSaga);
}
export function* watchFetchNextPatient() {
  yield takeLatest('FETCH_NEXT_PATIENT_LIST_REQUEST', fetchNextPatientListSaga);
}

export function* watchFetchPatientList() {
  yield takeLatest(FETCH_PATIENT_LIST_REQUEST, fetchPatientListSaga);
}
export function* watchUpdatePatient() {
  yield takeLatest(UPDATE_PATIENT_REQUEST, updatePatientSaga);
}
