import React from 'react';
import { FiHome, FiUsers, FiFolder, FiCalendar, FiBarChart2, FiSettings } from 'react-icons/fi';

const Menus = [
  { to: '/dashboard', icon: <FiHome />, label: 'Dashboard' },
  { to: '/patient-list', icon: <FiUsers />, label: 'Patients' },
  { to: '/add-patient', icon: <FiFolder />, label: 'Add Case' },
  { to: '/add-test', icon: <FiCalendar />, label: 'Add Test DataBase' },
  { to: '/add-doctor', icon: <FiBarChart2 />, label: 'Doctor DataBase' },
  { to: '/settings', icon: <FiSettings />, label: 'Settings' },
];

const Navbar = ({ isOpen, setIsOpen }) => {
  return (
    <div className="flex flex-col h-screen">
      <div className={`fixed inset-y-0 left-0 bg-white text-black w-64 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-200 ease-in-out lg:translate-x-0 shadow-lg`}>
        <div className=" hidden lg:flex items-center bg-blue-700 justify-center">
          <img src={`${process.env.PUBLIC_URL}/logo.png`}  alt="Logo" className="h-14" />
        </div>
        <ul className="mt-6 text-sm font-semibold uppercase">
          {Menus.map((menu, index) => (
            <li key={index} className="px-4 py-4 hover:bg-blue-100">
              <a href={menu.to} className="flex items-center space-x-4 uppercase" onClick={() => setIsOpen(false)}>
              <span className='uppercase text-xl bg-blue-100 shadow-md p-2 rounded-md font-bold'>{menu.icon}</span>
                <span className='uppercase '>{menu.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
