import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineDashboard, AiOutlineTeam, AiOutlineProject, AiOutlineCalendar, AiOutlineFileText, AiOutlinePieChart } from 'react-icons/ai';

const Sidebar = () => {
  return (
    <div className="bg-purple-800 text-white h-screen w-64 flex flex-col justify-between">
      <div>
        <div className="py-4 px-6">
          <h1 className="text-2xl font-bold">Your Company</h1>
        </div>
        <nav className="space-y-4">
          <Link to="/" className="flex items-center py-2 px-6 hover:bg-purple-700">
            <AiOutlineDashboard className="mr-2" />
            Dashboard
          </Link>
          <Link to="/add-patient" className="flex items-center py-2 px-6 hover:bg-purple-700">
            <AiOutlineTeam className="mr-2" />
            Add Case
          </Link>
          
        </nav>
      </div>
      <div className="py-4 px-6">
        <button className="bg-white text-purple-800 py-2 px-4 rounded-full">
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
