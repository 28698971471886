// collectionAgentSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/collectionAgentAction';
import axios from 'axios';
import httpCommon from '../../services/httpCommon';

  // Define functions to interact with collection agents API
  export const fetchCollectionAgentsApi = async () => {
    try {
      const response = await httpCommon.get('/collection-agents/'); // Use httpCommon.get
      return response.data;
    } catch (error) {
      console.error('Error fetching collection agents:', error);
      throw error;
    }
  };
  
  export const addCollectionAgentApi = async (collectionAgentData) => {
    try {
      const response = await httpCommon.post('/collection-agents/', collectionAgentData); // Use httpCommon.post
      return response.data;
    } catch (error) {
      console.error('Error adding collection agent:', error);
      throw error;
    }
  };

function* fetchCollectionAgentsSaga(action) {
  try {
    const collectionAgents = yield call(fetchCollectionAgentsApi);
    yield put({ type: actionTypes.FETCH_COLLECTION_AGENTS_SUCCESS, payload: collectionAgents });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_COLLECTION_AGENTS_FAILURE, payload: error.message });
  }
}
function* addCollectionAgentsSaga(action) {
    try {
        const response = yield call(addCollectionAgentApi, action.payload);
        yield put(actionTypes.addCollectionAgentSuccess(response.data));
      } catch (error) {
        yield put(actionTypes.addCollectionAgentFailure(error));
      }
  }

export function* watchFetchCollectionAgents() {
  yield takeLatest(actionTypes.FETCH_COLLECTION_AGENTS_REQUEST, fetchCollectionAgentsSaga);
}

// Similarly, implement sagas for adding collection agents

