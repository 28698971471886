// collectionCenterSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/collectionCenterAction';
import axios from 'axios';
import httpCommon from '../../services/httpCommon';

  // Define functions to interact with collection Centers API
  export const fetchCollectionCentersApi = async () => {
    try {
      const response = await httpCommon.get('/collection-center/');
      return response.data;
    } catch (error) {
      console.error('Error fetching collection Centers:', error);
      throw error;
    }
  };
  
  export const addCollectionCenterApi = async (collectionCenterData) => {
    try {
      const response = await httpCommon.post('/collection-centers/', collectionCenterData);
      return response.data;
    } catch (error) {
      console.error('Error adding collection Center:', error);
      throw error;
    }
  };

function* fetchCollectionCentersSaga(action) {
  try {
    const collectionCenters = yield call(fetchCollectionCentersApi);
    yield put({ type: actionTypes.FETCH_COLLECTION_CENTERS_SUCCESS, payload: collectionCenters });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_COLLECTION_CENTERS_FAILURE, payload: error.message });
  }
}
function* addCollectionCentersSaga(action) {
    try {
        const response = yield call(addCollectionCenterApi, action.payload);
        yield put(actionTypes.addCollectionCenterSuccess(response.data));
      } catch (error) {
        yield put(actionTypes.addCollectionCenterFailure(error));
      }
  }

export function* watchFetchCollectionCenters() {
  yield takeLatest(actionTypes.FETCH_COLLECTION_CENTERS_REQUEST, fetchCollectionCentersSaga);
}

// Similarly, implement sagas for adding collection Centers

