import React from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import  { Redirect } from 'react-router-dom'

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear localStorage
    localStorage.removeItem('accessToken');
    console.log("dflsjkflsdklfksdlkfl")
        if(!localStorage.getItem('accessToken')){
          console.log("dflsjkf34343lsdklfksdlkfl")
          navigate('/login')
      }
  };

  return (
    <AiOutlineLogout onClick={handleLogout} />
  );
};

export default Logout;
