import React, { useState, useEffect } from 'react';

const EditPatientForm = ({ setPatientData, patientData }) => {
  const [formData, setFormData] = useState({
    title: '',
    first_name: '',
    last_name: '',
    gender: '',
    age_years: '',
    age_months: '',
    age_days: '',
    mobile: '',
    email: '',
    address: '',
    adhar_no: '',
    UIHID: '',
  });

  useEffect(() => {
    if (patientData) {
      setFormData(patientData);
    }
  }, [patientData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedFormData = { ...formData, [name]: type === 'checkbox' ? checked : value };

    if (name === 'title') {
      if (['Mr', 'Shree', 'Master'].includes(value)) {
        updatedFormData = { ...updatedFormData, gender: 'Male' };
      } else {
        updatedFormData = { ...updatedFormData, gender: 'Female' };
      }
    }

    setFormData(updatedFormData);
    setPatientData(updatedFormData);
  };

  return (
    <div className="w-full mx-auto bg-blue-100 rounded px-8 pt-6 pb-8 my-4 shadow-2xl">
      <h2 className="text-2xl font-bold mb-4 text-blue-700">Edit Patient Detail</h2>
      <form>
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 py-4 px-8 shadow-2xl">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Title <span className="text-red-500">*</span>
            </label>
            <select
              className="shadow required appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            >
              <option value="">Select Title</option>
              <option value="Mr">Mr.</option>
              <option value="Mrs">Mrs.</option>
              <option value="Miss">Miss.</option>
              <option value="Smt">Smt.</option>
              <option value="Shree">Shree.</option>
              <option value="Ms">Ms.</option>
              <option value="Master">Master.</option>
              <option value="Babyof">Baby Of.</option>
              <option value="Dr">Dr.</option>
            </select>
          </div>
          {/* First Name */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              className="shadow required appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="firstName"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              placeholder="Enter First Name"
              required
            />
          </div>
          {/* Last Name */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
              Last Name
            </label>
            <input
              className="shadow required appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="lastName"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
          </div>
          {/* Gender */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Gender</label>
            <div>
              <input
                className="mr-2 leading-tight"
                type="radio"
                id="male"
                name="gender"
                value="Male"
                checked={formData.gender === 'Male'}
                onChange={handleChange}
              />
              <label className="mr-4 text-gray-700" htmlFor="male">Male</label>
              <input
                className="mr-2 leading-tight"
                type="radio"
                id="female"
                name="gender"
                value="Female"
                checked={formData.gender === 'Female'}
                onChange={handleChange}
              />
              <label className="text-gray-700" htmlFor="female">Female</label>
            </div>
          </div>
          {/* Date of Birth */}
          <div className='col-span-2'>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dob">
              Age
            </label>
            <div className="flex">
              <input
                className="shadow appearance-none border rounded w-full py-2 mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dob"
                type="text"
                name="age_years"
                placeholder='Yrs'
                value={formData.age_years}
                onChange={handleChange}
              />
              <label className="block text-gray-100 border px-3 rounded bg-blue-600 text-sm font-bold my-auto mb-2" htmlFor="dob">
                YRS
              </label>
              <input
                className="shadow appearance-none border rounded w-full px-3 mx-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dob"
                type="text"
                name="age_months"
                placeholder='Mnths'
                value={formData.age_months}
                onChange={handleChange}
              />
              <label className="block text-gray-100 border px-3 rounded my-auto bg-blue-600 text-sm font-bold mb-2" htmlFor="dob">
                Mnts
              </label>
              <input
                className="shadow appearance-none border rounded w-full mx-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dob"
                type="text"
                name="age_days"
                placeholder='Days'
                value={formData.age_days}
                onChange={handleChange}
              />
              <label className="block text-gray-100 border px-3 rounded bg-blue-600 text-sm font-bold my-auto mb-2" htmlFor="dob">
                Dys
              </label>
            </div>
          </div>
          {/* Mobile */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
              Mobile 
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="mobile"
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Enter Mobile Number"
              required
            />
          </div>
          {/* Email */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email 
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Email"
              required
            />
          </div>
          {/* Address */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
              Address
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter Address"
            />
          </div>
          {/* Aadhar */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="adhar_no">
              Aadhar Number
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="adhar_no"
              type="text"
              name="adhar_no"
              value={formData.adhar_no}
              onChange={handleChange}
              placeholder="Enter Aadhar Number"
            />
          </div>
          <div>
            <label className="block text-gray-700 text font-bold mb-2" htmlFor="UIHID">
              UIHID
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="UIHID"
              type="text"
              name="UIHID"
              value={formData.UIHID}
              onChange={handleChange}
              placeholder="Enter UIHID"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPatientForm;
