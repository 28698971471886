
import { FETCH_NORMAL_VALUE_REQUEST,FETCH_NORMAL_VALUE_SUCCESS,FETCH_NORMAL_VALUE_FAILURE } from '../actions/testDatabaseAction';


const initialState = {
  normal_value_data: [],
  loading: false,
  error: null,
};

const testNormalValueReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NORMAL_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NORMAL_VALUE_SUCCESS:
      return {
        ...state,
        normal_value_data: action.payload,
      };
    case FETCH_NORMAL_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    
    default:
      return state;
  }
};

export default testNormalValueReducer;