import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function PrivateRoute({ children }) {
    const isLogin = useSelector((state) => state.auth.isLogin);
    const accessToken = localStorage.getItem('accessToken')


    if (!accessToken) {
        return <Navigate to='/login' />
    }

  return children;
}