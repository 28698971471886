// collectionCenterActions.js
export const FETCH_COLLECTION_CENTERS_REQUEST = 'FETCH_COLLECTION_CENTERS_REQUEST';
export const FETCH_COLLECTION_CENTERS_SUCCESS = 'FETCH_COLLECTION_CENTERS_SUCCESS';
export const FETCH_COLLECTION_CENTERS_FAILURE = 'FETCH_COLLECTION_CENTERS_FAILURE';

export const ADD_COLLECTION_CENTER_REQUEST = 'ADD_COLLECTION_CENTER_REQUEST';
export const ADD_COLLECTION_CENTER_SUCCESS = 'ADD_COLLECTION_CENTER_SUCCESS';
export const ADD_COLLECTION_CENTER_FAILURE = 'ADD_COLLECTION_CENTER_FAILURE';
  
  export const fetchCollectionCentersRequest = () => ({
    type: FETCH_COLLECTION_CENTERS_REQUEST,
  });
  
  export const fetchCollectionCentersSuccess = (collectionCenters) => ({
    type: FETCH_COLLECTION_CENTERS_SUCCESS,
    payload: collectionCenters,
  });
  
  export const fetchCollectionCentersFailure = (error) => ({
    type: FETCH_COLLECTION_CENTERS_FAILURE,
    payload: error,
  });
  
  export const addCollectionCenterRequest = (collectionCenterData) => ({
    type: ADD_COLLECTION_CENTER_REQUEST,
    payload: collectionCenterData,
  });
  
  export const addCollectionCenterSuccess = (collectionCenter) => ({
    type: ADD_COLLECTION_CENTER_SUCCESS,
    payload: collectionCenter,
  });
  
  export const addCollectionCenterFailure = (error) => ({
    type: ADD_COLLECTION_CENTER_FAILURE,
    payload: error,
  });
  