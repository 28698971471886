import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import Navbar from '../components/Navbar';
import AddPatientForm from '../components/AddPatientForm';
import AddCaseForm from '../components/AddCaseForm';
import { addPatientRequest, fetchPatientRequest, updatePatientRequest } from '../store/actions/patientActions';// Import fetch and update actions
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';

const AddPatientPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { caseId } = useParams(); // Get patient case ID from URL params
  const [patientData, setPatientData] = useState({});
  const [caseData, setCaseData] = useState({});
  const [testData, setTestData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const error = useSelector(state => state.patient.error);
  const success = useSelector(state => state.patient.success);
  const patientState = useSelector((state) => state.patient);
  const { data: patientValue, loading: patientLoading, error: patientError } = patientState;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (caseId) {
      dispatch(fetchPatientRequest(caseId)); // Fetch patient case data if ID is provided
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (patientValue && caseId) {
      setPatientData(patientValue.patient);
      console.log(patientValue)
      setCaseData({
        referredBy: patientValue.referred_by,
        collectionCenter: patientValue.collection_center,
        agent: patientValue.collection_agent,
        collectionDate: patientValue.collection_date,
        reportingDate: patientValue.reporting_date,
        generateReportOnline: patientValue.generateReportOnline
      });
      setTestData(patientValue.test);
      setPaymentData({
        totalFee: patientValue.total_fee,
        discount: { amount: patientValue.discount, type: patientValue.discount_type },
        totalPaidAmount: patientValue.paid_amount,
        totalDue: patientValue.due_amount,
        paymentType: patientValue.payment_type,
        remark: patientValue.remark
      });
    }
  }, [patientValue, caseId,setCaseData]);

  const handleAddOrUpdatePatientAndCase = async () => {
    const currentDate = new Date();
    const dob = new Date(patientData.age_years, patientData.age_month - 1, patientData.age_day);
    const ageInYears = currentDate.getFullYear() - dob.getFullYear();

    if (ageInYears < 4) {
      alert('Age should be greater than 4 years.');
      return;
    }
    const requestData = {
      patient: patientData,
      referred_by: caseData.referredBy,
      collection_center: caseData.collectionCenter,
      collection_agent: caseData.agent,
      collection_date: caseData.collectionDate,
      reporting_date: caseData.reportingDate,
      generateReportOnline: caseData.generateReportOnline,
      total_fee: paymentData.totalFee,
      discount: paymentData.discount.amount,
      discount_type: paymentData.discount.type,
      paid_amount: paymentData.totalPaidAmount,
      due_amount: paymentData.totalDue,
      payment_type: paymentData.paymentType,
      discount_remark: paymentData.remark,
      test: testData,
      status: "PENDING",
      is_active: true
    };

    if (caseId) {
      dispatch(updatePatientRequest(caseId, requestData));
    } else {
      dispatch(addPatientRequest(requestData));
    }
  };

  useEffect(() => {
    if (success) {
      navigate(`/patient-detail/${patientValue.case_id}`);
      
    } else if (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 2000
      });
    }
  }, [error, success, navigate]);

  return (
    <div className="flex h-screen">
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex-1 lg:ml-64 ">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <AddPatientForm setPatientData={setPatientData} patientData={patientData} />
        <AddCaseForm caseData={caseData} setCaseData={setCaseData} setTestData={setTestData} setPaymentData={setPaymentData} caseData={caseData} />
        <div className='text-center py-4'>
          <button className='bg-blue-500 px-4 py-2 text-right rounded-md justify-end mx-auto text-white text-lg font-semibold hover:bg-blue-800' onClick={handleAddOrUpdatePatientAndCase}>Submit</button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddPatientPage;
