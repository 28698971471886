// collectionAgentReducer.js
import * as actionTypes from '../actions/collectionAgentAction';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const collectionAgentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COLLECTION_AGENTS_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_COLLECTION_AGENTS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case actionTypes.FETCH_COLLECTION_AGENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // Similarly, handle add collection agent actions
    default:
      return state;
  }
};

export default collectionAgentReducer;
