import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientRequest } from '../store/actions/patientActions';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    height: '100%',
    backgroundColor: '#ffffff',
  },
  leftSection: {
    width: '20%',
    padding: 20,
    boxSizing: 'border-box',
  },
  rightSection: {
    width: '80%',
    padding: 20,
    boxSizing: 'border-box',
    position: 'relative', 
  },
  uploadSection: {
    marginBottom: 20,
  },
  uploadTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  formGroup: {
    marginBottom: 10,
  },
  input: {
    width: '100%',
    padding: 5,
    border: '1px solid #ccc',
    borderRadius: 4,
  },
  header: {
    marginBottom: 30,
  },
  headerImage: {
    width: '100%',
    height: '100px',
  },
  patientDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  patientText: {
    marginBottom: 10,
    fontSize: 10,
    fontWeight:'bold'
  },
  body: {
    marginBottom: 20,
  },
  table: {
    borderWidth: 0, // Removed border
    width: '100%',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 20,
    fontSize: 10,
    padding: 5,
    margin: 0, // Adjusted margin
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    padding: 5,
    textAlign: 'center',
    alignItems: 'center',
  },
  tableCell: {
    flex: 1,
    wordWrap: 'break-word',
    padding: 5,
    width: '25%',
    textAlign: 'center',
    fontSize:'11',
    margin: 0, // Adjusted margin
  },
  signature: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    marginBottom: 20, // Added margin bottom
    position: 'absolute', // Position the signature section absolutely
    bottom: 60, // Adjust as needed to place it above the footer
    right: 0, // Align with the right edge of the container
  },
  signatureImage: {
    width: '80%',
    height: 'auto',
    marginBottom: 5,
  },
  signatureText: {
    fontSize: 14, // Increased font size
  },
  footer: {
    textAlign: 'center',
    marginTop: 20,
    borderTop: '1px solid #ccc',
    fontSize: 10,
    padding: 10,
    fontStyle: 'italic',
    position: 'absolute',
    bottom: 0,
    backgroundColor:'yellow',
    width: '100%',
  },
  fontGreen: {
    color: 'green',
    textAlign:'center',
    fontWeight: 'bold',
  },
  underline: {
    textDecoration: 'underline',
    marginTop:30,
    marginBottom:30
  },
  navBar:{
    backgroundColor: 'purple',
    borderRadius: 10,
    padding:'5px',
    top:0
  },
  header: {
    backgroundColor: 'purple',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 2, // Padding only for the text
    marginBottom: 10,
    width: '25%', // Take up 1/4 of the container's width
    alignItems: 'center', // Center the content horizontally
    marginHorizontal:'auto',
  },
  headerText: {
    color: 'white',
    fontWeight: 'heavy', // Explicitly specifying the font weight
    textAlign: 'center', // Center the text horizontally
    fontSize:11
  },
  endReport: {
    color: 'black',
    fontWeight: 'heavy', // Explicitly specifying the font weight
    textAlign: 'center', // Center the text horizontally
    fontSize:11
  },
});

const PDFReport = () => {
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const patientState = useSelector((state) => state.patient);
  const { data: patientData, loading: patientLoading, error: patientError } = patientState;

  const [signatureImage, setSignatureImage] = useState(null);
  const [letterheadImage, setLetterheadImage] = useState(null);
  const [letterheadWidth, setLetterheadWidth] = useState('');
  const [letterheadHeight, setLetterheadHeight] = useState('');

  useEffect(() => {
    dispatch(fetchPatientRequest(caseId));
  }, [dispatch, caseId]);

  if (patientLoading) {
    return <div>Loading...</div>;
  }

  if (patientError) {
    return <div>Error: {patientError.message}</div>;
  }

  const { lab_name, lab_address, email, phone, patient, test_panel, total_fee, discount, doctor_by, reporting_date } = patientData || {};

  const handleSignatureUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSignatureImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLetterheadUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLetterheadImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <div style={styles.uploadSection}>
          <Text style={styles.uploadTitle}>Letterhead</Text>
          <input type="file" accept="image/*" onChange={handleLetterheadUpload} />
          <div style={styles.formGroup}>
            <label htmlFor="letterheadWidth">Width:</label>
            <input
              type="number"
              id="letterheadWidth"
              value={letterheadWidth}
              onChange={(e) => setLetterheadWidth(e.target.value)}
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="letterheadHeight">Height:</label>
            <input
              type="number"
              id="letterheadHeight"
              value={letterheadHeight}
              onChange={(e) => setLetterheadHeight(e.target.value)}
              style={styles.input}
            />
          </div>
        </div>
        <div style={styles.uploadSection}>
          <Text style={styles.uploadTitle}>Signature</Text>
          <input type="file" accept="image/*" onChange={handleSignatureUpload} />
        </div>
        <Text style={styles.uploadTitle}>Dashboard<Link to="/dashboard">Dashboard</Link></Text>
        <h1 className='text-white underline uppercase text-lg mt-10 px-3 py-2 text-center bg-blue-800 rounded '><Link to="/dashboard">Dashboard</Link></h1>
      </div>
      <div style={styles.rightSection}>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
            {test_panel?.map((testItem, index) => {
              const testsPerPage = 4; // Number of tests per page
              const numTests = testItem?.report?.length || 0; // Number of tests in group
              const numPages = Math.ceil(numTests / testsPerPage); // Calculate number of pages
              const pages = [];

              // Push each page for the test group
              for (let i = 0; i < numPages; i++) {
                pages.push(
                  <Page key={index} style={{ padding: '50px' }}>
            
            <View style={styles.container}>
            
                <View style={styles.header}>
                    <Text style={styles.headerText}>Diagnosis Report</Text>
                </View>
                  <View style={styles.header1}>
                    {letterheadImage && (
                      <Image src={letterheadImage} style={styles.headerImage} />
                    )}
                  </View>
                  <View style={styles.patientDetails}>
                    <View style={styles.patientColumn}>
                      <Text style={[styles.patientText, styles.fontBold]}>
                        Patient Name: {patient ? `${patient.first_name} ${patient.last_name}` : 'N/A'}
                      </Text>
                      <Text style={styles.patientText}>
                      Age: {patient ? 
      `${patient.age_years} years ${patient.age_months !== '0' ? `${patient.age_months} months` : ''} ${patient.age_days !== '0' ? `${patient.age_days} days` : ''}` 
      : 'N/A'}
                      </Text>
                      <Text style={styles.patientText}>
                        Sex: {patient ? `${patient.gender}` : 'N/A'}
                      </Text>
                      <Text style={styles.patientText}>
                        Referred By: {patientData.doctor_by || 'N/A'}
                      </Text>
                      <Text style={styles.patientText}>
                        Center: {patientData.doctor_by || 'N/A'}
                      </Text>
                    </View>
                    <View style={styles.patientColumn}>
                      
                      <Text style={styles.patientText}>Registration Date: {patientData.created_at ? new Date(patientData.created_at).toLocaleDateString() : 'N/A'}</Text>
                      <Text style={styles.patientText}>Reported Date: {patientData.reporting_date ? new Date(patientData.reporting_date).toLocaleDateString() : 'N/A'}</Text>
                      <Text style={styles.patientText}>Collection Date: {patientData.collection_date ? new Date(patientData.collection_date).toLocaleDateString() : 'N/A'}</Text>
                    </View>
                    
                  </View>
                  <View style={styles.body}>
                    <View style={[styles.tableRow, styles.tableHeader, { backgroundColor: '#f0f0f0' }]}>
                      <Text style={[styles.tableCell, styles.fontBold]}>Test Name</Text>
                      <Text style={[styles.tableCell, styles.fontBold]}>Observed Value</Text>
                      <Text style={[styles.tableCell, styles.fontBold]}>Units</Text>
                      <Text style={[styles.tableCell, styles.fontBold]}>Reference Range</Text>
                    </View>
                    
                    <React.Fragment key={index}>
  {testItem?.test_group && (
    <Text style={[styles.fontGreen, styles.underline, { marginVertical: 10 }]}>{testItem?.test_group_name}</Text>
  )}
  {testItem?.report?.slice(testsPerPage * i, testsPerPage * (i + 1)).map((reportItem, reportIndex) => (
    <React.Fragment key={`report_${reportIndex}`}>
      <View style={styles.tableRow}>
        <Text style={styles.tableCell}>{reportItem?.name}</Text>
        <Text style={styles.tableCell}>: {reportItem?.result}</Text>
        <Text style={styles.tableCell}>{reportItem?.unit}</Text>
        <Text style={styles.tableCell}>{reportItem?.normal_value}</Text>
      </View>
      <View style={[styles.tableRow, { marginLeft: testItem?.test_group ? 20 : 10, marginVertical: 10 }]}>
        <Text style={[styles.fontBold, { flex: 1, marginBottom: 5 }]}>Method:</Text>
        <Text style={{ flex: 3 }}>{reportItem?.name}</Text>
      </View>
      {/* Interpretation */}
      <View style={[styles.tableRow, { marginLeft: testItem?.test_group ? 20 : 10, marginBottom: 10 }]}>
        <Text style={[styles.fontBold, { flex: 1, marginTop: 0 }]}>Interpretation:</Text>
        <Text style={{ flex: 3 }}>{reportItem?.interpretation}</Text>
      </View>
    </React.Fragment>
  ))}
</React.Fragment>

                  </View>
                  <View style={styles.endReport} >
                      <Text>****End of the report.****</Text>
                  </View>
                  <View style={styles.signature}>
                          <View>
                            {signatureImage && <Image src={signatureImage} style={styles.signatureImage} />}
                            <Text style={styles.signatureText}>Lab Incharge</Text>
                          </View>
                          
                      </View>
                  {/* Signature and footer */}
                  
                  <View style={styles.footer} fixed>
                        <Text>{`Lab Name: ${lab_name || 'N/A'}`}</Text>
                        <Text>{`Lab Address: ${lab_address || 'N/A'}`}</Text>
                        <Text>{`Email: ${email || 'N/A'}`}</Text>
                        <Text>{`Phone: ${phone || 'N/A'}`}</Text>
                      </View>
            </View>
          </Page>
                );
              }

              return pages; // Return array of pages for this test group
            })}
          </Document>
     
    </PDFViewer>
      </div>
    </div>
  );
};

export default PDFReport;
