import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUserMd, FaUserInjured, FaUserTie, FaVials } from 'react-icons/fa';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Card from '../components/Card';
import httpCommon from '../services/httpCommon';

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpCommon.get('/dashboard/'); // Update this URL to your Django backend URL
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const { total_patients, total_tests, total_cases, total_doctors, total_agents, total_centers } = data;

  return (
    <div className="flex h-screen">
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex-1 lg:ml-64">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="p-6 bg-gray-50">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <Card keyName="Total Patients" value={total_patients} icon={<FaUserInjured />} />
            <Card keyName="Total Tests" value={total_tests} icon={<FaVials />} />
            <Card keyName="Total Cases" value={total_cases} icon={<FaUserTie />} />
            <Card keyName="Total Doctors" value={total_doctors} icon={<FaUserMd />} />
            <Card keyName="Total Agents" value={total_agents} icon={<FaUserTie />} />
            <Card keyName="Total Centers" value={total_centers} icon={<FaUserMd />} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
