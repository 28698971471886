import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatientRequest } from '../store/actions/patientActions';
import { addTestValueRequest } from '../store/actions/testReportAction';
import Navbar from '../components/Navbar';
import { toast } from 'react-toastify';
import Header from '../components/Header';

const AddTestValuePage = () => {
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patientState = useSelector((state) => state.patient);
  const { data: patientData, loading: patientLoading, error: patientError } = patientState;

  const [updatedTestValues, setUpdatedTestValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPatientRequest(caseId));
  }, [dispatch, caseId]);

  const handleInputChange = (testId, value) => {
    if (!value) {
      setUpdatedTestValues((prevValues) => ({
        ...prevValues,
        [testId]: 0,
      }));
    }else{
      setUpdatedTestValues((prevValues) => ({
        ...prevValues,
        [testId]: value,
      }));
    }
  
  };

  const handleInputFocus = (testId) => {
    setUpdatedTestValues((prevValues) => ({
      ...prevValues,
      [testId]: '',
    }));
  };

  const handleSave = () => {
    const updatedData = Object.keys(updatedTestValues).map((testId) => ({
      test: testId,
      patient_case: caseId,
      result: updatedTestValues[testId],
    }));

    dispatch(addTestValueRequest(updatedData));
    toast.success('Test Result updated successfully!', {
      position: 'top-right',
      autoClose: 2000
    });
    navigate(`/view-pdf-report/${caseId}`);
  };

  const handlePrint = () => {
    navigate(`/view-pdf-report/${caseId}`);
  };

  if (patientLoading) {
    return <div>Loading...</div>;
  }

  if (patientError) {
    return <div>Error: {patientError.message}</div>;
  }

  const { test_panel } = patientData || {};

  return (
    <>
       <div className="flex h-screen">
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex-1 lg:ml-64">
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="p-6 bg-gray-50">
        <button onClick={handlePrint} className="bg-green-500 text-white px-4 py-2 rounded mb-4">
          Print Report
        </button>
        
        <div>
          <h2 className="text-lg font-bold mb-4">Test Panels</h2>
          <table className="min-w-full border-collapse block md:table">
            <thead className="block md:table-header-group bg-blue-900">
              <tr className="border border-gray-300 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto md:relative">
                <th className="p-2 text-gray-100 font-bold text-left block md:table-cell">Test Name</th>
                <th className="p-2 text-gray-100 font-bold text-left block md:table-cell">Result</th>
                <th className="p-2 text-gray-100 font-bold text-left block md:table-cell">Unit</th>
                <th className="p-2 text-gray-100 font-bold text-left block md:table-cell">References</th>
              </tr>
            </thead>
            <tbody className="block md:table-row-group">
              {test_panel?.map((panel, index) => (
                <React.Fragment key={index}>
                  {panel.test_panel_report && (
                    <>
                      <tr className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                        <td className="p-2 text-left block md:table-cell font-bold text-purple-700" colSpan="4">
                          {panel.test_panel_report.name}
                        </td>
                      </tr>
                      {panel.test_panel_report.test_panel_report?.map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                          <tr className="bg-gray-50 border border-gray-200 md:border-none block md:table-row">
                            <td className="p-2 text-left block md:table-cell font-bold text-purple-500 pl-4" colSpan="4">
                              {group.name}
                            </td>
                          </tr>
                          {group.report?.map((test, testIndex) => (
                            <React.Fragment key={testIndex}>
                              <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                                <td className="p-2 text-left block md:table-cell pl-8">{test.name}</td>
                                <td className="p-2 text-left block md:table-cell">
                                  <input
                                    type="text"
                                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                                    value={updatedTestValues[test.id] || test.result}
                                    onChange={(e) => handleInputChange(test.id, e.target.value)}
                                    
                                  />
                                </td>
                                <td className="p-2 text-left block md:table-cell">{test.unit}</td>
                                <td className="p-2 text-left block md:table-cell">{test.normal_value}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                      <tr className="bg-gray-50 border border-gray-200 md:border-none block md:table-row">
                        <td className="p-2 text-left block md:table-cell font-bold text-purple-500 pl-4" colSpan="4">
                          Individual Tests
                        </td>
                      </tr>
                      {panel.test_panel_report.test_report?.map((test, testIndex) => (
                        <React.Fragment key={testIndex}>
                          <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                            <td className="p-2 text-left block md:table-cell pl-8">{test.name}</td>
                            <td className="p-2 text-left block md:table-cell">
                              <input
                                type="text"
                                className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                                value={updatedTestValues[test.id] || test.result}
                                onChange={(e) => handleInputChange(test.id, e.target.value)}
                              />
                            </td>
                            <td className="p-2 text-left block md:table-cell">{test.unit}</td>
                            <td className="p-2 text-left block md:table-cell">{test.normal_value}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                  {panel.test_report && (
                    <React.Fragment key={panel.test_report.id}>
                      <tr className="bg-white border border-gray-300 md:border-none block md:table-row">
                        <td className="p-2 text-left block md:table-cell font-bold text-green-700">
                          <div>{panel.test_report.name}</div>
                          {panel.test_report.method && (
                            <div>
                              <strong>Method:</strong> {panel.test_report.method || 'N/A'}
                            </div>
                          )}
                        </td>
                        <td className="p-2 text-left block md:table-cell">
                          <input
                            type="text"
                            className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                            value={updatedTestValues[panel.test_report.id] || panel.test_report.result}
                            onChange={(e) => handleInputChange(panel.test_report.id, e.target.value)}
                          />
                        </td>
                        <td className="p-2 text-left block md:table-cell">{panel.test_report.unit}</td>
                        <td className="p-2 text-left block md:table-cell">{panel.test_report.normal_value}</td>
                      </tr>
                    </React.Fragment>
                  )}
                  {panel.test_group_report && (
                    <>
                      <tr className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                        <td className="p-2 text-left block md:table-cell font-bold text-blue-700" colSpan="4">
                          {panel.test_group_report.name}
                        </td>
                      </tr>
                      {panel.test_group_report.report?.map((test, testIndex) => (
                        <React.Fragment key={testIndex}>
                          <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                            <td className="p-2 text-left block md:table-cell pl-8">{test.name}</td>
                            <td className="p-2 text-left block md:table-cell">
                              <input
                                type="text"
                                className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                                value={updatedTestValues[test.id] || test.result}
                                onChange={(e) => handleInputChange(test.id, e.target.value)}
                              />
                            </td>
                            <td className="p-2 text-left block md:table-cell">{test.unit}</td>
                            <td className="p-2 text-left block md:table-cell">{test.normal_value}</td>
                          </tr>
                          {(test.interpretation || test.method) && (
                            <tr className="bg-white border border-gray-200 md:border-none block md:table-row">
                              <td className="p-2 text-left block md:table-cell pl-8" colSpan="4">
                                <strong>Interpretation:</strong> {test.interpretation || 'N/A'}, <strong>Method:</strong> {test.method || 'N/A'}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="px-4 py-2 mt-4">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
        
      </div>
      </div>
      </div>
    </>
  );
};

export default AddTestValuePage;
