import React, { useState, useEffect } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestDatabaseRequest, resetTestDatabase } from '../store/actions/testDatabaseAction';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchPatientRequest, updatePatientRequest } from '../store/actions/patientActions';
import Navbar from './Navbar';
import { ToastContainer, toast } from 'react-toastify';
import Header from './Header';

const EditLabSection = () => {
    const dispatch = useDispatch();
    const { caseId } = useParams();
    const navigate = useNavigate();

    const testDatabaseState = useSelector((state) => state.testDatabase);
    const { data: tests, nextPageUrl } = testDatabaseState;

    const patientState = useSelector((state) => state.patient);
    const { data: patientValue, loading: patientLoading, error: patientError, success: patientSuccess } = patientState;

    const [testData, setTestData] = useState([]);
    const [paymentData, setPaymentData] = useState({
        totalFee: 0,
        discount: { amount: '', type: '₹' },
        totalPaidAmount: 0,
        totalDue: 0,
        paymentType: 'Cash',
        remark: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [remark, setRemark] = useState('');
    const [selectedTests, setSelectedTests] = useState([]);
    const [discount, setDiscount] = useState({ amount: '', type: '₹' });
    const [totalPaidAmount, setTotalPaidAmount] = useState(0);
    const [paymentType, setPaymentType] = useState('Cash');
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredTests, setFilteredTests] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (caseId) {
            dispatch(fetchPatientRequest(caseId));
        }
    }, [dispatch, caseId]);

    useEffect(() => {
        if (patientValue && patientValue.test_panel) {
            const collectTestNames = (item) => {
                let testNames = [];

                if (item.test_report) {
                    testNames.push(item.test_report);
                } else if (item.test_group_report) {
                    testNames.push(item.test_group_report);
                } else if (item.test_panel_report) {
                    testNames.push(item.test_panel_report);
                    item.test_panel_report.test_panel_report.forEach((subItem) => {
                        testNames = testNames.concat(collectTestNames(subItem));
                    });
                }

                return testNames;
            };

            const selectedTestNames = patientValue.test_panel.reduce((acc, item) => {
                return acc.concat(collectTestNames(item));
            }, []);

            setSelectedTests(selectedTestNames);
            setDiscount({ amount: patientValue.discount, type: patientValue.discount_type });
            setRemark(patientValue.discount_remark);
        }
    }, [patientValue]);

    const handleSearchInputChange = (e) => {
        const searchTerm = e.target.value.toLowerCase().trim();
        setSearchTerm(searchTerm);

        if (searchTerm !== '') {
            dispatch(fetchTestDatabaseRequest(searchTerm));
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };

    const handleLoadMoreTests = () => {
        if (nextPageUrl) {
            const searchParams = new URLSearchParams(nextPageUrl.split('?')[1]);
            const page = searchParams.get('page');
            dispatch(fetchTestDatabaseRequest(searchTerm, page));
        }
    };

    const handleRemarkInputChange = (e) => {
        setRemark(e.target.value);
    };

    const handleTestSelect = (test) => {
        const isTestSelected = selectedTests.some((selectedTest) => selectedTest.id === test.id);
        if (!isTestSelected) {
            setSelectedTests([...selectedTests, test]);
        }

        setSearchTerm('');
        setShowDropdown(false);
    };

    const handleTestRemove = (test) => {
        const updatedTests = selectedTests.filter((t) => t.id !== test.id);
        setSelectedTests(updatedTests);
    };

    const handleDiscountChange = (e) => {
        setDiscount({ ...discount, amount: e.target.value });
    };

    const handleDiscountTypeChange = (type) => {
        setDiscount({ ...discount, type });
    };

    const calculateTotalFee = () => {
        return selectedTests.reduce((total, test) => total + test.rate, 0);
    };

    const totalFee = calculateTotalFee();
    const totalAmount = totalFee - (discount.type === '%' ? (totalFee * discount.amount) / 100 : discount.amount);
    const totalDue = totalAmount - totalPaidAmount;

    useEffect(() => {
        setTestData(selectedTests);
        setPaymentData({ totalFee, discount, totalPaidAmount, totalDue, paymentType, remark });
    }, [selectedTests, totalFee, discount, totalPaidAmount, totalDue, paymentType, remark]);

    const handleSave = () => {
        const requestData = {
            patient: patientValue,
            total_fee: paymentData.totalFee,
            discount: paymentData.discount.amount,
            discount_type: paymentData.discount.type,
            paid_amount: paymentData.totalPaidAmount,
            due_amount: paymentData.totalDue,
            payment_type: paymentData.paymentType,
            discount_remark: paymentData.remark,
            test: testData,
            status: "PENDING",
            is_active: true
        };

        if (caseId) {
            dispatch(updatePatientRequest(caseId, requestData));
        }
    };

    useEffect(() => {
        if (patientError) {
            toast.error(patientError, {
                position: 'top-right',
                autoClose: 2000
            });
        }
        if (patientSuccess) {
            toast.success('Patient updated successfully!', {
                position: 'top-right',
                autoClose: 2000
            });
            setTimeout(() => {
                navigate(`/patient-detail/${caseId}`);
            }, 2000);
        }
    }, [patientError, patientSuccess, navigate, caseId]);

    return (
        <div className="flex h-screen">
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className="flex-1 lg:ml-64">
                <Header isOpen={isOpen} setIsOpen={setIsOpen} />
                <div className="flex justify-start my-4 ">
                    <a href={`/add-test-value/${caseId}`} className="bg-blue-500 text-white font-semibold py-2 px-4 mx-5 rounded-md">Add Report Value</a>
                    <a href={`/view-pdf-report/${caseId}`} className="bg-purple-500 text-white font-semibold py-2 px-4 mx-5 rounded-md">View Report</a>
                    <a href={`/patient-detail/${caseId}`} className="bg-black text-white font-semibold py-2 px-4 mx-5 rounded-md"> Patient Detail</a>
                </div>
                <div className="flex flex-col md:flex-row bg-blue-100 shadow-md rounded px-4 py-6 my-4 items-center justify-between relative">
                    <AiFillCloseCircle
                        className="text-red-500 absolute top-0 right-0 cursor-pointer"
                        onClick={() => console.log('Close button clicked')}
                    />
                    <input
                        type="text"
                        placeholder="Search Test"
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        className="shadow appearance-none group border rounded py-2 px-3 mx-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 md:mb-0 w-full md:w-1/3"
                    />
                    <div className="flex w-full md:w-full mb-2 border border-blue-500 p-3 bg-blue-400 md:mb-0 md:mr-2 flex-wrap">
                        {selectedTests.map((test) => (
                            <div key={test.id} className="flex items-center bg-gray-200 rounded-md shadow-xl px-3 py-2 mb-2 mr-2">
                                <span>{test.name}</span>
                                <AiFillCloseCircle className="text-red-500 cursor-pointer ml-2" onClick={() => handleTestRemove(test)} />
                            </div>
                        ))}
                    </div>
                    <div className="w-full md:w-1/2 mb-2 md:mb-0 p-2 flex justify-between items-center">
                        <span>Total Fee:</span>
                        <input
                            type="number"
                            placeholder="Fee"
                            value={totalFee.toFixed(2)}
                            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-auto"
                            readOnly
                        />
                    </div>
                    <div className="w-full flex flex-col md:flex-row items-center mb-2 md:mb-0 md:w-1/2">
                        <input
                            type="text"
                            placeholder="Discount"
                            value={discount.amount}
                            onChange={handleDiscountChange}
                            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2 w-full md:w-1/2 mb-2 md:mb-0"
                        />
                        <select
                            value={discount.type}
                            onChange={(e) => handleDiscountTypeChange(e.target.value)}
                            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-auto"
                        >
                            <option value="₹">₹</option>
                            <option value="%">%</option>
                        </select>
                    </div>
                    <input
                        type="text"
                        placeholder="Remarks"
                        value={remark}
                        onChange={handleRemarkInputChange}
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-1/2 ml-0 md:ml-4"
                    />
                    {showDropdown && (
                        <div className="absolute top-full left-0 w-full md:w-1/4 bg-white shadow-md rounded border border-gray-300 mt-2 z-10">
                            <ul className="p-2">
                                {tests?.results?.map((test) => (
                                    <li
                                        key={test.id}
                                        className="cursor-pointer hover:bg-gray-100 py-1 px-2"
                                        onClick={() => handleTestSelect(test)}
                                    >
                                        {test.name} - ({test.short_name}) - (₹ {test.rate})
                                    </li>
                                ))}
                            </ul>
                            {nextPageUrl && (
                                <div className="text-center py-2">
                                    <button onClick={handleLoadMoreTests} className="text-blue-500 hover:underline">
                                        Load More
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex justify-center my-4">
                    <div className="w-full max-w-2xl">
                        <div className="grid grid-cols-1 gap-4 bg-white shadow-md rounded-lg px-6 py-4">
                            <div>
                                <h2 className="text-xl font-semibold mb-4">Total Fee</h2>
                                <div className="flex justify-between mb-2">
                                    <span>Total Fee:</span>
                                    <span>₹{totalFee.toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span>Discount:</span>
                                    <span>{discount.type === '%' ? `${discount.amount}%` : `₹${discount.amount}`}</span>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold mb-4">Payment Details</h2>
                                <div className="flex justify-between mb-2">
                                    <span>Total Amount:</span>
                                    <span>₹{totalAmount.toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span>Total Paid Amount:</span>
                                    <input
                                        type="number"
                                        value={totalPaidAmount}
                                        onChange={(e) => setTotalPaidAmount(parseFloat(e.target.value) || 0)}
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-1/2"
                                    />
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span>Total Due:</span>
                                    <span>₹{totalDue.toFixed(2)}</span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <label htmlFor="payment_type">Select Payment Type:</label>
                                    <select
                                        id="payment_type"
                                        name="payment_type"
                                        value={paymentType}
                                        onChange={(e) => setPaymentType(e.target.value)}
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-auto"
                                    >
                                        <option value="Cash">Cash</option>
                                        <option value="UPI">UPI</option>
                                        <option value="Card">Card</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={handleSave}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditLabSection;
